import PhotoGallery from "../components/PhotoGallery";
import vacun01 from '../assets/02_galeria/23_campaña_vacunacion2023/01.avif';
import vacun02 from '../assets/02_galeria/23_campaña_vacunacion2023/02.avif';
import vacun03 from '../assets/02_galeria/23_campaña_vacunacion2023/03.avif';
import vacun04 from '../assets/02_galeria/23_campaña_vacunacion2023/04.avif';
import vacun05 from '../assets/02_galeria/23_campaña_vacunacion2023/05.avif';
import vacun06 from '../assets/02_galeria/23_campaña_vacunacion2023/06.avif';
import vacun07 from '../assets/02_galeria/23_campaña_vacunacion2023/07.avif';
import vacun08 from '../assets/02_galeria/23_campaña_vacunacion2023/08.avif';

const CampSaludyVacu2023 = () => {
  const galleryImages = [
    { img: vacun01 }, { img: vacun02 }, { img: vacun03 }, { img: vacun04 }, { img: vacun05 }, { img: vacun06 }, { img: vacun07 }, { img: vacun08 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Campaña de Salud y Vacunación Invernal (Azafrán 219)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default CampSaludyVacu2023