import PhotoGallery from "../components/PhotoGallery";
import asuntosgrales1 from '../assets/02_galeria/48_asuntos_generales/1.avif';
import asuntosgrales2 from '../assets/02_galeria/48_asuntos_generales/2.avif';
import asuntosgrales3 from '../assets/02_galeria/48_asuntos_generales/3.avif';
import asuntosgrales4 from '../assets/02_galeria/48_asuntos_generales/4.avif';
import asuntosgrales5 from '../assets/02_galeria/48_asuntos_generales/5.avif';

const AsuntosGeneralesMar = () => {
  const galleryImages = [

    { img: asuntosgrales1 }, { img: asuntosgrales2 }, { img: asuntosgrales3 }, { img: asuntosgrales4 }, { img: asuntosgrales5 },

  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión con delegados para hablar de asuntos generales <br /> (05 de marzo de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default AsuntosGeneralesMar