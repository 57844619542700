import PhotoGallery from "../components/PhotoGallery";
import misacom01 from '../assets/02_galeria/30_misa_comite/01.avif';
import misacom02 from '../assets/02_galeria/30_misa_comite/02.avif';
import misacom03 from '../assets/02_galeria/30_misa_comite/03.avif';
import misacom04 from '../assets/02_galeria/30_misa_comite/04.avif';
import misacom05 from '../assets/02_galeria/30_misa_comite/05.avif';
import misacom06 from '../assets/02_galeria/30_misa_comite/06.avif';
import misacom07 from '../assets/02_galeria/30_misa_comite/07.avif';
import misacom08 from '../assets/02_galeria/30_misa_comite/08.avif';
import misacom09 from '../assets/02_galeria/30_misa_comite/09.avif';
import misacom10 from '../assets/02_galeria/30_misa_comite/10.avif';

const MisaComite2023 = () => {
  const galleryImages = [
    { img: misacom01 }, { img: misacom02 }, { img: misacom03 }, { img: misacom04 }, { img: misacom05 }, { img: misacom06 }, { img: misacom07 }, { img: misacom08 }, { img: misacom09 }, { img: misacom10 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Misa celebrada en el Comité por el 12 de diciembre</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default MisaComite2023