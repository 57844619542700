import Tittle from '../components/Tittle'
import NavBarClose from '../components/NavBarClose'
import GoToTop from '../components/GoToTop'
import '../styles/Nosotros.css'

const Nosotros = () => {
  return (
    <>
      <header>
        <Tittle />
        <NavBarClose />
      </header>
      <main>
        <section className='containerHistory'>
          <h3 className='titleHistory'>ANTECEDENTES	HISTÓRICOS</h3>
          <p className='textHistory'>
            Como beneficiarios del movimiento social revolucionario de 1919 en México, importantes sectores de trabajadores procedieron activamente en la definición del propósito de estar en posibilidades de participar activamente en la definición del rumbo que habría de seguir nuestra sociedad emergente. <br /><br />

            Dentro de este contexto los trabajadores al servicio del Estado, tuvieron significativa participación en el fortalecimiento de dicho proceso; correspondiendo al trabajador adscrito al Departamento Agrario, atender los más sentidos reclamos del sector campesino coadyuvando lealmente con los regímenes revolucionarios, desde el año de 1935, como precursor del sindicalismo burocrático organizado. Es así como con fecha 12 de abril de 1934, durante el régimen presidencial del General Abelardo L. Rodríguez, los trabajadores del Departamento Agrario, se constituyeron nacionalmente en un Comité de Acción Social y Cultural, para la defensa de sus derechos laborales. <br /><br />

            Durante el gobierno del presidente, General de División Lázaro Cárdenas del Río, al darse a conocer el proyecto del Estatuto de los Trabajadores al Servicio de los Poderes de la Unión, los empleados del entonces Departamento Agrario, se constituyeron con fecha 31 de julio de 1937, en sindicato de los empleados de la mencionada dependencia de la federación, formulándose los Estatutos que entraron en vigor el 12 de abril de 1938, posteriormente fue promulgado el Estatuto de los Trabajadores al Servicio de la Unión, el 5 de noviembre del mismo año. Los principales preceptos del Estatuto Jurídico pasaron a integrar el apartado “B” del Artículo 123 Constitucional, con fecha 5 de diciembre de 1960. Durante el período Presidencial del C. Licenciado Adolfo López Mateos, el 17 de diciembre de 1966, se expidió la Ley Federal de los Trabajadores al Servicio del Estado, la cual entró en vigor el 29 del mismo mes y año, reglamentaria del citado apartado “B” constitucional. <br /><br />

            Con fecha 1º de enero de 1959, nuestra organización adecuó su denominación a la nueva, de Sindicato Nacional de Trabajadores del Departamento de Asuntos Agrarios y Colonización, con motivo del cambio del nombre de la dependencia. <br /><br />

            Como consecuencia de las reformas a las disposiciones que rigen la relación entre el Estado y sus servidores, los miembros del Sindicato de Trabajadores del Departamento de Asuntos Agrarios y Colonización, en su XXVII Asamblea General Ordinaria de Representantes, celebrada los días 27, 28 y 29 del mes de junio de 1968, acordaron actualizar su estatuto sindical a fin de adecuarlo con la ley en vigor y en la X Asamblea General Extraordinaria de Representantes, celebrada los días 26, 27 y 28 de junio de 1969, se aprobaron las reformas. <br /><br />

            Por decreto Presidencial de fecha 29 de diciembre de 1974, publicado en el Diario Oficial de la Federación el 31 del mismo mes y año, el Departamento de Asuntos Agrarios y Colonización, se convirtió en Secretaria de la Reforma Agraria, por lo que en la XII Asamblea Nacional Extraordinaria de Representantes, adoptó el nombre de Sindicato Nacional de Trabajadores de la Secretaría de la Reforma Agraria, adecuando sus estatutos a esta nueva denominación.<br /><br />

            Esta disposición presidencial estimuló en alto grado a los trabajadores de esta dependencia del Ejecutivo Federal, considerando que implicaba el reconocimiento de la labor desarrollada en beneficio de la población del sector rural, propiciando que en términos presupuestales se contara con mayores recursos para el desarrollo de las actividades y el acceso a mejores tabuladores en la remuneración del trabajador.<br /><br />

            Por reformas a la Ley Orgánica de la Administración Pública Federal de fecha 02 de enero de 2013, publicada en el Diario Oficial de la Federación el mismo día, mes y año, la Secretaría de la Reforma Agraria se convirtió en la Secretaría de Desarrollo Agrario, Territorial y Urbano, por lo que en el XXV Consejo Nacional Ordinario de Representantes, adoptó el nombre de Sindicato Nacional de Trabajadores de la Secretaría de Desarrollo Agrario, Territorial y Urbano, adecuando sus estatutos a esta nueva denominación.<br /><br />

            En la XXXIV Asamblea Nacional Ordinaria de representantes, celebrada los días 6 y 7 de junio de 1975, se acordó reformar los Estatutos sindicales, los que a propuesta de la Comisión Redactora se aprobaron en la XIII Asamblea Nacional Extraordinaria de representantes, celebrada el día 8 de junio de 1976, en Campeche, Camp.<br /><br />

            En el III Consejo Nacional Ordinario de representantes, celebrado los días 6 y 7 de agosto de 1981, en el Puerto de Veracruz, se acordó reformar los Estatutos<br /><br />

            sindicales, los que a propuesta de la Comisión Redactora se aprobaron en el III Consejo Nacional Extraordinario, celebrado el día 16 de julio de 1982, en la Ciudad de México, D. F.<br /><br />

            En el V Congreso Nacional Extraordinario, celebrado el 31 de julio de 1985, se acordó reformar los Estatutos a propuesta de la Comisión Dictaminadora y Redactora, reformas que se aprobaron en la fecha citada, en la Ciudad de México, D. F.<br /><br />

            En el VI Congreso Nacional Extraordinario, celebrado el 15 de agosto de 1988, se reformaron disposiciones del Fondo Solidario de Retiro, lo que a propuesta de la Comisión Dictaminadora y Redactora se aprobaron en la fecha indicada, en la Ciudad de México, D. F.<br /><br />

            En el VII Congreso Nacional Extraordinario, celebrado el 16 de agosto de 1988, se acordó reformar los Estatutos, reformas que a propuesta de la Comisión Dictaminadora y Redactora se aprobaron en la fecha señalada, en la Ciudad de México, D.F.<br /><br />

            En el VIII Congreso Nacional Extraordinario, celebrado los días 6 y 7 de agosto de 1990, se acordó implementar reformas a los Estatutos, mismas que a propuesta de la Comisión Dictaminadora y Redactora se aprobaron en la fecha mencionada, en la Ciudad de Puebla, Pue.<br /><br />

            En el IX Congreso Nacional Extraordinario, celebrado los días 25 y 26 de agosto de 1997, se acordó implementar reformas a los Estatutos, mismas que a propuesta de la Comisión Dictaminadora y Redactora se aprobaron en la fecha citada, en la Ciudad de México, D.F.<br /><br />

            En el X Congreso Nacional Extraordinario de representantes, llevado a cabo el día 26 de septiembre de 2000, se acordó reformar los Estatutos a propuesta de la Comisión Dictaminadora, reformas que fueron aprobadas al siguiente día de la instalación del Congreso, en la Ciudad de México, D.F.<br /><br />

            En el XI Congreso Nacional Extraordinario celebrado los días 28 y 29 de agosto de 2003 se acordó reformar los estatutos, a propuesta de la Comisión Dictaminadora, mismos que se aprobaron en la fecha mencionada, en el Puerto de Acapulco, Guerrero.<br /><br />

            En el XII Congreso Nacional Extraordinario, celebrado los días 28 y 29 de agosto de 2006, se acordó implementar reformas a los Estatutos, mismas que a propuesta de la Comisión Dictaminadora y Redactora se aprobaron en la fecha citada en la Ciudad de México, D.F.<br /><br />

            En el XIII Congreso Nacional Extraordinario, celebrado los días 26, 27 y 28 de agosto de 2009, se acordó implementar reformas a los Estatutos, mismas que a propuesta de la Comisión Dictaminadora y Redactora se aprobaron en la fecha citada en Ixtapa Zihuatanejo, Guerrero.<br /><br />

            En el XIV Congreso Nacional Extraordinario, celebrado los días 27 y 28 de agosto de 2012, se acordó implementar reformas a los Estatutos, mismas que a propuesta de la Comisión Dictaminadora y Redactora se aprobaron en la fecha citada en la Ciudad de México, D.F.<br /><br />

            En el XV Congreso Nacional Extraordinario, celebrado los días 26, 27 y 28 de agosto de 2015, se acordó implementar reformas a los Estatutos, mismas que a propuesta de la Comisión Dictaminadora y Redactora se aprobaron en la fecha citada en Ixtapa Zihuatanejo, Guerrero.<br /><br />

            En el XVI Congreso Nacional Extraordinario, celebrado en la Ciudad de México los días 5 y 6 de diciembre de 2017 se acordó implementar reformas a los estatutos, mismas que a propuesta de la Comisión Dictaminadora y Redactora se aprobaron en la fecha citada.<br /><br />
          </p>
        </section>
        <section className='containerHistory'>
          <h3 className='titleHistory'>EXPOSICIÓN DE MOTIVOS</h3>
          <p className='textHistory'>
            Con un cuadro de representantes preocupados, que ha incorporado su convicción en beneficio de los servidores agrarios, la actual dirigencia sindical, representa el nuevo rumbo que permita orientar el quehacer sindical hacia las presentes circunstancias en las que se desenvuelvan sus agremiados. <br /><br />

            Es necesario efectuar alianzas con las organizaciones campesinas, las diversas fracciones partidistas que coincidan con los postulados del sindicato, y dar a conocer a la sociedad la necesidad de continuar con las etapas que debe cumplir la dependencia, tales como organización y capacitación a los campesinos de México, para que se inserte en un verdadero desarrollo rural integral. <br /><br />

            Es de vital importancia que el Comité Ejecutivo Nacional, sus dirigentes y los trabajadores, conformen un proyecto de largo plazo, en el que se resalten y defiendan las funciones básicas de la Secretaría de Desarrollo Agrario, Territorial y Urbano, que conlleve a su permanencia dentro de la Administración Pública Federal y con el propósito de salvaguardar nuestra fuente laboral. <br /><br />

            Lo anterior fundamenta las siguientes disposiciones: <br /><br />

            Se fortalece la capacitación a los trabajadores, con la impartición de cursos que mejoren su nivel intelectual y de autovaloración, quedando con este propósito bajo la tutela de la Secretaría de Educación y Capacitación, su organización e impartición. <br /><br />

            Se propicia el diálogo con la base trabajadora a través de visitas por parte de integrantes del Comité Ejecutivo Nacional a las áreas de trabajo, fortaleciendo la relación entre “Trabajador y su Dirigente”. <br /><br />

            Los foros para el análisis y modificación de Estatutos, se constituyen en voceros de las inquietudes del trabajador. <br /><br />

            El restablecimiento del voto directo y secreto, para elegir delegados a los Congresos Nacionales como demanda del trabajador y compromiso de vigencia democrática de la dirigencia sindical, que reitera la adecuación a las circunstancias actuales, en los presentes Estatutos se ve plenamente superado, al darse un trascendental paso en el avance de la vida democrática de nuestra organización, al implementarse la elección del Secretario General del Comité Ejecutivo Nacional, mediante el voto universal, secreto y directo de todos los agremiados. <br /><br />

            Determinación de procedimientos para la elección de Secretario General de Comité Ejecutivo Nacional, con aspectos específicos redundando en la transparencia de las decisiones derivadas del trabajador como elector. <br /><br />

            Programas de apoyo al salario reflejado en el Fondo de Ahorro Capitalizable, con aportación derivada del fondo de la organización sindical. <br /><br />
          </p>
        </section>
        <section className='containerHistory'>
          <h3 className='titleHistory'>DECLARACIÓN DE PRINCIPIOS</h3>
          <p className='textHistory'>
            Se declara: <br /><br />

            PRIMERO.- Debe tomarse de manera prioritaria y como una obligación y compromiso por parte de nuestra organización sindical, la lucha permanente y organizada siempre apegada a la Ley, para la defensa de los derechos sociales y laborales de los trabajadores, y de la vigencia y permanencia de la Secretaría de Desarrollo Agrario, Territorial y Urbano, y de su Órgano Desconcentrado Registro Agrario Nacional y/o su reestructuración y adecuación a los nuevos tiempos en que le toque desempeñarse, y/o la transferencia de sus trabajadores con sus derechos a salvo a otras dependencias del Ejecutivo Federal, así como los derechos de los trabajadores que sean transferidos de otras dependencias. <br /><br />

            SEGUNDO.- Una de las máximas realizaciones de la Revolución Mexicana, ha sido la entrega de la tierra a los campesinos, para hacerla producir de forma organizada, y una vez concluida esta meta, se abre otra perspectiva dentro del proceso de la Secretaría de Desarrollo Agrario, Territorial y Urbano y de su Órgano Desconcentrado Registro Agrario Nacional, que es la de continuar trabajando en la etapa concerniente al fomento de la organización rural, hábitat, rescate de espacios públicos, vivienda digna, vivienda rural, apoyo a los vecindados en condiciones de pobreza patrimonial, para regularizar asentamientos humanos irregulares (PASPRAH), prevención de riesgos en los asentamientos humanos, consolidación de reservas humanas, reordenamiento de unidades habitacionales, apoyo de jóvenes emprendedores agrarios, plataforma indispensable y necesaria para que se puedan dar con toda la seguridad en un marco jurídico las inversiones productivas en el campo en un fuerte valladar al abandono y emigración de la mano de obra rural sobre todo de los jóvenes contribuyendo para ello con el desempeño de sus funciones para el logro de un desarrollo agrario sustentable. <br /><br />

            TERCERO.- Los trabajadores de la Secretaría de Desarrollo Agrario, Territorial y Urbano y de su Órgano Desconcentrado Registro Agrario Nacional, ejercen el derecho de asociación en defensa de sus intereses y salvaguarda de sus garantías de acuerdo con las normas establecidas en la Ley Federal de los Trabajadores al Servicio del Estado, reglamentaria del apartado “B”, del artículo 123 Constitucional. <br /><br />

            CUARTO.- Nuestra demanda permanente respecto a los principios de libertad, autodeterminación y solidaridad sustento de la independencia de organismos sindicales; por considerar que el movimiento social organizado, con plena autonomía y conciencia de sus deberes y derechos, garantiza la permanencia de las instituciones revolucionarias del país. Será además invariablemente respetuosa de las distintas corrientes de opinión; encauzándolas hacia beneficios generales de sus agremiados. <br /><br />

            El Sindicato Nacional de Trabajadores de la Secretaría de Desarrollo Agrario, Territorial y Urbano, es democrático, considerando a la democracia no solamente como una estructura jurídica y un régimen político, sino como un sistema de vida fundado en el constante mejoramiento económico, social y cultural de sus agremiados. <br /><br />

            QUINTO.- La participación de la mujer en la lucha sindical, es un espacio que han conquistado en el devenir histórico sirviendo de ejemplo a las generaciones futuras. <br /><br />

            SEXTO.- Los servidores del Estado, deben pugnar porque las leyes evolucionen como expresión de las conquistas sociales de la clase trabajadora, respondiendo en todo momento a las exigencias del progreso nacional. <br /><br />

            SEPTIMO.- Para la defensa de los derechos de los trabajadores, la asociación sindical constituye el medio adecuado para preservar y aumentar sus conquistas, para tal objeto, es necesario que todos por igual dirigentes y dirigidos, formen una unidad sólida e indestructible, con la convicción de que, de esa unidad depende la existencia misma de la organización. <br /><br />

            OCTAVO.- La Federación de Sindicatos de Trabajadores al Servicio del Estado, es expresión de unidad, de lucha y de solidaridad de la burocracia nacional, y el medio más eficaz para mejorar las prestaciones sociales y defender sus derechos, por lo que, nuestra organización acatando sus Estatutos y programas de acción, está adherida a esta central bajo el pacto de solidaridad e irrestricto respeto a la autonomía de nuestro Sindicato. <br /><br />

            NOVENO.- Solidaridad con otras organizaciones sociales que luchen por el bienestar de la clase trabajadora, si no contravienen la declaración de principios de nuestra organización. <br /><br />
          </p>
        </section>

      </main>
      <GoToTop />
    </>

  )
}

export default Nosotros;