import PhotoGallery from "../components/PhotoGallery";
import viSon1 from '../assets/02_galeria/09_vist_son23/01.avif'
import viSon2 from '../assets/02_galeria/09_vist_son23/02.avif'


const VisitSon23 = () => {
  const galleryImages = [
    { img: viSon1 }, { img: viSon2 }
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión de nuestro Secretario General en el estado de Sonora</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default VisitSon23