import PhotoGallery from "../components/PhotoGallery";
import reuniontamales01 from '../assets/02_galeria/41_reunion_dia_candelaria2024/1.avif';
import reuniontamales02 from '../assets/02_galeria/41_reunion_dia_candelaria2024/2.avif';
import reuniontamales03 from '../assets/02_galeria/41_reunion_dia_candelaria2024/3.avif';
import reuniontamales04 from '../assets/02_galeria/41_reunion_dia_candelaria2024/4.avif';
import reuniontamales05 from '../assets/02_galeria/41_reunion_dia_candelaria2024/5.avif';

const Reunion02Febrero2024 = () => {
  const galleryImages = [
    { img: reuniontamales01 }, { img: reuniontamales02 }, { img: reuniontamales03 }, { img: reuniontamales04 }, { img: reuniontamales05 }
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión del Comité para celebrar el día de la Candelaria <br /> (02 de febrero de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default Reunion02Febrero2024