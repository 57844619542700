import PhotoGallery from "../components/PhotoGallery";
import semsal01dia01 from '../assets/02_galeria/54_dia01/1.avif';
import semsal01dia02 from '../assets/02_galeria/54_dia01/2.avif';
import semsal01dia03 from '../assets/02_galeria/54_dia01/3.avif';
import semsal01dia04 from '../assets/02_galeria/54_dia01/4.avif';
import semsal01dia05 from '../assets/02_galeria/54_dia01/5.avif';

const Dia01SemSalud = () => {
  const galleryImages = [

    { img: semsal01dia01 }, { img: semsal01dia02 }, { img: semsal01dia03 }, { img: semsal01dia04 }, { img: semsal01dia05 },

  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Jornada de la Salud (Primer Día) <br /> (13 de mayo de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default Dia01SemSalud