import PhotoGallery from "../components/PhotoGallery";
import reunionplanilla1901 from '../assets/02_galeria/42_reunion_planilla_del19/1.avif';
import reunionplanilla1902 from '../assets/02_galeria/42_reunion_planilla_del19/2.avif';
import reunionplanilla1903 from '../assets/02_galeria/42_reunion_planilla_del19/3.avif';
import reunionplanilla1904 from '../assets/02_galeria/42_reunion_planilla_del19/4.avif';
import reunionplanilla1905 from '../assets/02_galeria/42_reunion_planilla_del19/5.avif';
import reunionplanilla1906 from '../assets/02_galeria/42_reunion_planilla_del19/6.avif';
import reunionplanilla1907 from '../assets/02_galeria/42_reunion_planilla_del19/7.avif';
import reunionplanilla1908 from '../assets/02_galeria/42_reunion_planilla_del19/8.avif';

const ReunionEleccionPlanilla19 = () => {
  const galleryImages = [
    { img: reunionplanilla1901 }, { img: reunionplanilla1902 }, { img: reunionplanilla1903 }, { img: reunionplanilla1904 }, { img: reunionplanilla1905 }, { img: reunionplanilla1906 }, { img: reunionplanilla1907 }, { img: reunionplanilla1908 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión de la Delegación 19 para la elección de Delegada y Vocales <br /> (06 de febrero de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default ReunionEleccionPlanilla19