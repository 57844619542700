import '../styles/Galeria.css';
import Tittle from '../components/Tittle';
import NavBarClose from '../components/NavBarClose';
import GoToTop from '../components/GoToTop';
import Congreso22 from '../images/01ConsCong';
import AsamGral23 from '../images/02AsamGral23';
import AñosServicio2022 from '../images/03AñosServ22';
import AsambleasGralesCongre23 from '../images/04AsamGralCong23';
import ExcursAca23 from '../images/05ExcursAca23';
import LogisConseCong23 from '../images/06LogCons23';
import PrepConsCong23 from '../images/07PrepConsCong23';
import ConsCong23 from '../images/08ConsCong23';
import VisitSon23 from '../images/09VisitSon23';
import VisitMor23 from '../images/10VisitMor23';
import SimSep23 from '../images/11SimSep23';
import ReuDel11sep23 from '../images/12ReuDel11sep23';
import ReuCond25sep23 from '../images/13ReuCond25sep23';
import VisitVeracruz23 from '../images/14VistVer23';
import ReunionDelCondiciones23 from '../images/15ReuDelCond23';
import Reunnion2aRevCondiciones from '../images/16Reu_2a_rev_Condi';
import BazosCaidosRan from '../images/17_brazos_caidos_ran';
import AmparoCondiciones2023 from '../images/18AmparoCondiciones2023';
import DañosCasaAcapulco from '../images/19DañosCasaAcapulco';
import ExcChignaNov23 from '../images/20ExcursionChignahuapan';
import SeguimAmpYNueSindi from '../images/21SeguimientoAmparoYNuevoSindicato';
import EleccionesAGA from '../images/22EleccionesAGA';
import CampSaludyVacu2023 from '../images/23CampañaVacunacion23';
import Pensionissste2023 from '../images/24Pensionissste';
import RespuestaYexpulsion from '../images/25Amparo_y_Expulsion';
import DescuentosPorFaltas from '../images/26DescuentosPorFaltas';
import InformativaDeleg11 from '../images/27InformacionDeleg11';
import InfoyConvocatoriaDel19 from '../images/28Infoy ConvoDel19';
import VentaNochebuenas23 from '../images/29VentaNochebuenas';
import MisaComite2023 from '../images/30MisaComite';
import MisaAzafran2023 from '../images/31MisaAzafran2023';
import VotacionesDeleg19 from '../images/32VotacionesDel19';
import BrindisComite2023 from '../images/33BrindisComite2023';
import Reunion01yRosca from '../images/34ReunionRosca24';
import InfoISSSTEdel030713 from '../images/35InfoISSSTEdels030713';
import ReunionAGA2024 from '../images/36ReunionAGA2024';
import InfoISSSTEdel0208 from '../images/37InfoISSSTEdels0208';
import InfoISSSTEdel19 from '../images/38InfoISSSTEdel19';
import InfoISSSTEdel05ySecc10 from '../images/39InfoISSSTEdel5secc10';
import InfoISSSTEdel006 from '../images/40InfoISSSTEdel06';
import Reunion02Febrero2024 from '../images/41reunion02febrero2024';
import ReunionEleccionPlanilla19 from '../images/42ReunionPlanilla19';
import DelegadosPlazasEscalafon from '../images/43ReuDelPlazasEscalafon';
import ComitePlazasEscalafon from '../images/44ReuComPlazasEscalafon';
import SorteoComitePlaza from '../images/45SorteoComPlazasEscalafon';
import ReunionDocumentosPlazas from '../images/46ReunionDocumentoPlaza';
import GanadoresPlazas from '../images/47GanadoresPlazas';
import AsuntosGeneralesMar from '../images/48AsuntosGenerales';
import ExcursionTlaco from '../images/49ExcursionTlaxco';
import ReunionRanMarzo from '../images/50ReunionRan';
import ReunionComiteSecretario from '../images/51ReunionComiteSecretario';
import ReunionDelegadosSecretario from '../images/52ReunionDelegadosSecretario';
import JornadaDeLaSalud from '../images/53JornadaDeLaSalud';
import Dia01SemSalud from '../images/54SemSaludDia1';
import Dia02SemSalud from '../images/55SemSaludDia2';
import Dia03SemSalud from '../images/56SemSaludDia3';
import ExcursionAcapulco2024 from '../images/57ExcursionAcxapulco2024';
import Congreso2024Agosto28 from '../images/58Congreso2024Agosto28';
import Congreso2024Agosto29 from '../images/59Congreso2024Agosto29';
import Congreso2024Agosto30 from '../images/60Congreso2024Agosto30';

const Galeria = () => {
  return (
    <>
      <header className='setHeading'>
        <Tittle />
        <NavBarClose />
      </header>
      <main className='setMain'>
        <Congreso2024Agosto30 />
        <Congreso2024Agosto29 />
        <Congreso2024Agosto28 />
        <ExcursionAcapulco2024 />
        <Dia03SemSalud />
        <Dia02SemSalud />
        <Dia01SemSalud />
        <JornadaDeLaSalud />
        <ReunionDelegadosSecretario />
        <ReunionComiteSecretario />
        <ReunionRanMarzo />
        <ExcursionTlaco />
        <AsuntosGeneralesMar />
        <GanadoresPlazas />
        <ReunionDocumentosPlazas />
        <SorteoComitePlaza />
        <ComitePlazasEscalafon />
        <DelegadosPlazasEscalafon />
        <ReunionEleccionPlanilla19 />
        <Reunion02Febrero2024 />
        <InfoISSSTEdel006 />
        <InfoISSSTEdel05ySecc10 />
        <InfoISSSTEdel19 />
        <InfoISSSTEdel0208 />
        <ReunionAGA2024 />
        <InfoISSSTEdel030713 />
        <Reunion01yRosca />
        <BrindisComite2023 />
        <VotacionesDeleg19 />
        <MisaAzafran2023 />
        <MisaComite2023 />
        <VentaNochebuenas23 />
        <InfoyConvocatoriaDel19 />
        <InformativaDeleg11 />
        <DescuentosPorFaltas />
        <Pensionissste2023 />
        <RespuestaYexpulsion />
        <CampSaludyVacu2023 />
        <EleccionesAGA />
        <SeguimAmpYNueSindi />
        <ExcChignaNov23 />
        <DañosCasaAcapulco />
        <AmparoCondiciones2023 />
        <BazosCaidosRan />
        <Reunnion2aRevCondiciones />
        <ReunionDelCondiciones23 />
        <VisitVeracruz23 />
        <ReuCond25sep23 />
        <ReuDel11sep23 />
        <SimSep23 />
        <VisitMor23 />
        <VisitSon23 />
        <ConsCong23 />
        <PrepConsCong23 />
        <LogisConseCong23 />
        <ExcursAca23 />
        <AsambleasGralesCongre23 />
        <AñosServicio2022 />
        <AsamGral23 />
        <Congreso22 />
      </main>
      <GoToTop />
    </>
  )
}
export default Galeria;

