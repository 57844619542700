import PhotoGallery from "../components/PhotoGallery";
import viMor1 from '../assets/02_galeria/10_visit_mor23/01.avif'
import viMor2 from '../assets/02_galeria/10_visit_mor23/02.avif'
import viMor3 from '../assets/02_galeria/10_visit_mor23/03.avif'
import viMor4 from '../assets/02_galeria/10_visit_mor23/04.avif'
import viMor5 from '../assets/02_galeria/10_visit_mor23/05.avif'
import viMor6 from '../assets/02_galeria/10_visit_mor23/06.avif'
import viMor7 from '../assets/02_galeria/10_visit_mor23/07.avif'
import viMor8 from '../assets/02_galeria/10_visit_mor23/08.avif'
import viMor9 from '../assets/02_galeria/10_visit_mor23/09.avif'
import viMor10 from '../assets/02_galeria/10_visit_mor23/10.avif'


const VisitMor23 = () => {
  const galleryImages = [
    { img: viMor1 }, { img: viMor2 }, { img: viMor3 }, { img: viMor4 }, { img: viMor5 }, { img: viMor6 }, { img: viMor7 }, { img: viMor8 }, { img: viMor9 }, { img: viMor10 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión de nuestro Secretario General en el estado de Morelos</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default VisitMor23