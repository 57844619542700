import PhotoGallery from "../components/PhotoGallery";
import dele01 from '../assets/02_galeria/27_informativa_deleg11/01.avif';
import dele02 from '../assets/02_galeria/27_informativa_deleg11/02.avif';
import dele03 from '../assets/02_galeria/27_informativa_deleg11/03.avif';
import dele04 from '../assets/02_galeria/27_informativa_deleg11/04.avif';
import dele05 from '../assets/02_galeria/27_informativa_deleg11/05.avif';
import dele06 from '../assets/02_galeria/27_informativa_deleg11/06.avif';

const InformativaDeleg11 = () => {
  const galleryImages = [
    { img: dele01 }, { img: dele02 }, { img: dele03 }, { img: dele04 }, { img: dele05 }, { img: dele06 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión de personal del ISSSTE con la Delegación 11 para dar información sobre la jubilación y cuentas individuales<br /> (04 de diciembre de 2023)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default InformativaDeleg11