/* eslint-disable jsx-a11y/iframe-has-title */
import Tittle from '../components/Tittle';
import NavBarClose from '../components/NavBarClose';
import NavBarFooter from '../components/NavBarFooter';
import '../styles/Contacto.css';
import GoToTop from '../components/GoToTop';
import { Facebook, Instagram, Twitter } from '../images/ImagesHome';

const Contacto = () => {
  return (
    <>
      <header className='setHeading'>
        <Tittle />
        <NavBarClose />
      </header>
      <main className='setMain'>

        <section className='contactContainer'>
          <p className='titleExt'>Redes Sociales</p>
          <article className='blockContainerContact'>
            <a className='network' href='https://www.facebook.com/sindicato.nacional.sedatu' target="blank"><Facebook /></a>
            <a className='network' href='https://www.instagram.com/sindicato.nacional.sedatu/' target="blank"><Instagram /></a>
            <a className='network' href='https://twitter.com/SindNacSedatu' target='blank'><Twitter /></a>
          </article>
        </section>

        <section className='contactContainer'>
          <p className='titleExt'>Buzón</p>
          <article className='blockContainerContact'>
            <p className='textDirection'>escribenos@sntsedatu.org
            </p>
          </article>
        </section>

        <section className='contactContainer'>
          <p className='titleExt'>Dirección</p>
          <article className='blockContainerContact'>
            <p className='textDirection'>Av. Insurgentes Nte. 102 <br />Col. Santa María la Ribera <br />Alc. Cuauhtémoc, C.P. 06400 <br />Ciudad de Mexico, CDMX</p>
            <iframe className='mapDirection' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.2808697776854!2d-99.15720162497635!3d19.44345354033461!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f8d0797a1b5b%3A0xad3b297ed547f0ac!2sAv.%20Insurgentes%20Nte.%20102%2C%20Sta%20Mar%C3%ADa%20la%20Ribera%2C%20Cuauht%C3%A9moc%2C%2006400%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1689633522244!5m2!1ses-419!2smx" ></iframe>
          </article>
        </section>

        <section className='contactContainer'>
          <p className='titleExt'>Teléfonos</p>
          <article className='blockContainerContact2'>
            <p className='textPhones'><strong>Planta Baja: </strong>55 90 57 58 85</p>
            <p className='areaLocation'>Secretaría de Deportes y Acción Juvenil <br />Oficialía Mayor <br />Comisión Nacional de Vigilancia</p>
          </article>
          <hr className='splitter' />
          <article className='blockContainerContact2'>
            <p className='textPhones'><strong>1er Piso: </strong>55 90 57 58 85</p>
            <p className='areaLocation'>Secretaria General <br />Secretaría de Finanzas <br />Secretaría de de Escalafón </p>
          </article>
          <hr className='splitter' />
          <article className='blockContainerContact2'>
            <p className='textPhones'><strong>2° Piso: </strong>55 90 56 80 04</p>
            <p className='areaLocation'>Secretaría de Trabajo y Conflicos Of. Centrales <br />Secretaría de Previsión y Asistencia Social, Pensiones y Jubilaciones <br />Secretaría de Prestaciones Económicas <br />Secretaría de Educación y Capacitación <br />Secretaría de Turismo Social <br />Secretaría de Prensa y Relaciones Públicas y del Exterior <br />Comisión Nacional de Honor y Justicia</p>
          </article>
          <hr className='splitter' />
          <article className='blockContainerContact2'>
            <p className='textPhones'><strong>3er Piso: </strong>55 90 55 19 75</p>
            <p className='areaLocation'>Secretaría de Trabajo y Conflictos Of. Foraneas <br />Secretaría de Organización <br />Secretaría de Vivienda <br />Secretaría de Comercialización <br />Comisión Nacional de Accion Politica <br />Comisión Nacional de Asuntos Jurídicos</p>
          </article>
        </section>

      </main >
      <footer>
        <NavBarFooter />
      </footer>
      <GoToTop />
    </>
  )
}

export default Contacto;