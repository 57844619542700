import PhotoGallery from "../components/PhotoGallery";
import prep01 from '../assets/02_galeria/07_Prep_ConsCong_23/Preparativos-01.avif'
import prep02 from '../assets/02_galeria/07_Prep_ConsCong_23/Preparativos-02.avif'
import prep03 from '../assets/02_galeria/07_Prep_ConsCong_23/Preparativos-03.avif'
import prep04 from '../assets/02_galeria/07_Prep_ConsCong_23/Preparativos-04.avif'
import prep05 from '../assets/02_galeria/07_Prep_ConsCong_23/Arribo-05.avif'
import prep06 from '../assets/02_galeria/07_Prep_ConsCong_23/Arribo-06.avif'
import prep07 from '../assets/02_galeria/07_Prep_ConsCong_23/Arribo-07.avif'
import prep08 from '../assets/02_galeria/07_Prep_ConsCong_23/Arribo-08.avif'



const PrepConsCong23 = () => {
  const galleryImages = [
    { img: prep01 }, { img: prep02 }, { img: prep03 }, { img: prep04 }, { img: prep05 }, { img: prep06 }, { img: prep07 }, { img: prep08 }

  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Preparativos para el XXXIII Consejo Nacional Ordinario y XVIII Congreso Nacional Extraordinario</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default PrepConsCong23