import '../styles/Images.css';

//Comunicados
import asamblea from '../assets/01_comunicados/01_asamblea.avif';
import acuerdos from '../assets/01_comunicados/02_acuerdosCGT.avif';
import objecion from '../assets/01_comunicados/03_objecion.avif';
import telefonos from '../assets/01_comunicados/04_telefonos.avif';
import rol from '../assets/01_comunicados/05_rol.avif';
import transporte from '../assets/01_comunicados/06_ transporte.avif';
import lentes from '../assets/01_comunicados/07_lentes.avif';
import convConsejo from '../assets/01_comunicados/08_convConsejo.avif';
import convCongreso from '../assets/01_comunicados/09_convCongreso.avif';
import acuerdosFtse from '../assets/01_comunicados/10_acuerdosFtse.avif';
import haciendaTab from '../assets/01_comunicados/11_haciendaTab.avif';
import laJornada2407 from '../assets/01_comunicados/12_jornada2407.avif';
import lineaCredencial from '../assets/01_comunicados/13_lineamientocredencial.avif';
import movEscalafon23 from '../assets/01_comunicados/14_escalafon.avif';
import RemServ23 from '../assets/01_comunicados/15_rem-serv-23.avif';
import premAntig23 from '../assets/01_comunicados/16_premio-antig-23.avif';
import comunic070923 from '../assets/01_comunicados/17_comunicado-07-Sep-23.avif';
import uniftec23 from '../assets/01_comunicados/18_uniformes-tecnicos-23.avif';
import excurNov23 from '../assets/01_comunicados/19_excursion_nov_23.avif';
import calav2023 from '../assets/01_comunicados/20_calaverita.avif';
import imposicion from '../assets/01_comunicados/21_com_imposicion.avif';
import condi2023 from '../assets/01_comunicados/22_ condiciones23.avif';
import cala2023 from '../assets/01_comunicados/23_calaveritas23.avif';
import calaYofre23 from '../assets/01_comunicados/24_calaveritas_ofrendas23.avif';
import reyes2023 from '../assets/01_comunicados/25_oficio_reyes23.avif';
import flores2023 from '../assets/01_comunicados/26_flores.avif';
import solicempasedtu from '../assets/01_comunicados/27_sol_venta_cempa.avif';
import esquela from '../assets/01_comunicados/28_esquela.avif';
import carrera from '../assets/01_comunicados/29_carrera.avif';
import floAza from '../assets/01_comunicados/30_flores_aza_modulos.avif';
import floRan from '../assets/01_comunicados/31_flores_ran.avif';
import separacion from '../assets/01_comunicados/32_separacion.avif';
import soliherencia from '../assets/01_comunicados/33_solherenplazas.avif';
import respherencia from '../assets/01_comunicados/34_respherenplazas.avif';
import solistand from '../assets/01_comunicados/35_stand.avif';
import sinadornos from '../assets/01_comunicados/36_sin_adorno_electrico.avif';
import dic25 from '../assets/01_comunicados/37_25_diciembre.avif';
import ene1o from '../assets/01_comunicados/38_1o_enero.avif';
import rolvacacional2024 from '../assets/01_comunicados/39_rol_vacacional_2024.avif';
import feb05 from '../assets/01_comunicados/40_05_febrero.avif';
import ofijoelayala2024 from '../assets/01_comunicados/41_oficio_Joel20240201AYAJZ9.avif';
import excursionTlaco from '../assets/01_comunicados/42_excTlaco.avif';
import distribucionplazas from '../assets/01_comunicados/43Plazas2024.avif';
import nuevoscostosacapulco from '../assets/01_comunicados/44nuevos_costos.avif';
import obituario2024 from '../assets/01_comunicados/45obituario.avif';
import resamparo from '../assets/01_comunicados/46respuesta_amparo.avif';
import marzo18 from '../assets/01_comunicados/47_18_marzo.avif';
import riesgotrabajo from '../assets/01_comunicados/48_riesgo_trabajo.avif';
import excurAcapulco from '../assets/01_comunicados/49excuAca.avif';
import convocatoriaSecretario from '../assets/01_comunicados/50_convocatoria-secretario.avif';
import cambioaseguradora from '../assets/01_comunicados/51_cambio_aseguradora.avif';
import agroasemex from '../assets/01_comunicados/52_agroasemex.avif';
import semsalud from '../assets/01_comunicados/53_salud.avif';
import mayo1o from '../assets/01_comunicados/54_1o_mayo.avif';
import esquelaSara from '../assets/01_comunicados/55_sara_montiel.avif';
import acuerdosFstse2024 from '../assets/01_comunicados/56_acuerdosFstse.avif';
import convCongr2024 from '../assets/01_comunicados/57_convocatoriaCongreso.avif';
import formPonecias from '../assets/01_comunicados/58_formatoPonencias.avif';
import excChinahuapan26Oct from '../assets/01_comunicados/59_exc26octubre.avif'
import octubre1o from '../assets/01_comunicados/60_1o_octubre.avif'

//Comunicados
const Asamblea = () => {
  return (
    <img className='img-home' src={asamblea} alt="" />
  );
}
const Acuerdos = () => {
  return (
    <img className='img-home' src={acuerdos} alt="" />
  );
}
const Objecion = () => {
  return (
    <img className='img-home' src={objecion} alt="" />
  );
}
const Telefonos = () => {
  return (
    <img className='img-home' src={telefonos} alt="" />
  )
}
const Rol = () => {
  return (
    <img className='img-home' src={rol} alt="" />
  );
}
const Transporte = () => {
  return (
    <img className='img-home' src={transporte} alt="" />
  )
}
const Lentes = () => {
  return (
    <img className='img-home' src={lentes} alt='' />
  );
}
const ConvConsejo = () => {
  return (
    <img className='img-home' src={convConsejo} alt='' />
  );
}
const ConvCongreso = () => {
  return (
    <img className='img-home' src={convCongreso} alt='' />
  );
}
const AcuerdosFtse = () => {
  return (
    <img className='img-home' src={acuerdosFtse} alt='' />
  );
}
const HaciendaTab = () => {
  return (
    <img className='img-home' src={haciendaTab} alt='' />
  );
}
const LaJornada2407 = () => {
  return (
    <img className='img-home' src={laJornada2407} alt='' />
  );
}
const LineamientosCredencial = () => {
  return (
    <img className='img-home' src={lineaCredencial} alt='' />
  );
}
const Escalafon2023 = () => {
  return (
    <img className='img-home' src={movEscalafon23} alt='' />
  );
}
const RemAñosServ23 = () => {
  return (
    <img className='img-home' src={RemServ23} alt='' />
  );
}
const PremioAntig23 = () => {
  return (
    <img className='img-home' src={premAntig23} alt='' />
  );
}
const ComCondEsca = () => {
  return (
    <img className='img-home' src={comunic070923} alt='' />
  );
}
const UniformTec23 = () => {
  return (
    <img className='img-home' src={uniftec23} alt='' />
  );
}
const ExcursionNov2023 = () => {
  return (
    <img className='img-home' src={excurNov23} alt='' />
  );
}
const ConvCalyOfre23 = () => {
  return (
    <img className='img-home' src={calav2023} alt='' />
  );
}
const ComImposicion = () => {
  return (
    <img className='img-home' src={imposicion} alt='' />
  );
}
const Condi2023 = () => {
  return (
    <img className='img-home' src={condi2023} alt='' />
  );
}
const Cala23 = () => {
  return (
    <img className='img-home' src={cala2023} alt='' />
  );
}
const ConvCala2023 = () => {
  return (
    <img className='img-home' src={calaYofre23} alt='' />
  );
}
const OficioReyes23 = () => {
  return (
    <img className='img-home' src={reyes2023} alt='' />
  );
}
const FloresOct23 = () => {
  return (
    <img className='img-home' src={flores2023} alt='' />
  );
}
const SolCempa = () => {
  return (
    <img className='img-home' src={solicempasedtu} alt='' />
  );
}
const EsquelaNov23 = () => {
  return (
    <img className='img-home' src={esquela} alt='' />
  );
}
const CarreraNOv23 = () => {
  return (
    <img className='img-home' src={carrera} alt='' />
  );
}
const FloresAzafran = () => {
  return (
    <img className='img-home' src={floAza} alt='' />
  );
}
const FloresRan = () => {
  return (
    <img className='img-home' src={floRan} alt='' />
  );
}
const SeparacionSindicalizados = () => {
  return (
    <img className='img-home' src={separacion} alt='' />
  );
}
const SolHerenciaPlazas = () => {
  return (
    <img className='img-home' src={soliherencia} alt='' />
  )
}
const RespHerenciaPlazas = () => {
  return (
    <img className='img-home' src={respherencia} alt='' />
  )
}
const SolicStand = () => {
  return (
    <img className='img-home' src={solistand} alt='' />
  )
}
const SinAdornoElect = () => {
  return (
    <img className='img-home' src={sinadornos} alt='' />
  )
}
const Diciembre25 = () => {
  return (
    <img className='img-home' src={dic25} alt='' />
  )
}
const Enero1o = () => {
  return (
    <img className='img-home' src={ene1o} alt='' />
  )
}
const RolVacacional2024 = () => {
  return (
    <img className='img-home' src={rolvacacional2024} alt='' />
  )
}
const Febrero05 = () => {
  return (
    <img className='img-home' src={feb05} alt="" />
  )
}
const OficioPeticionJoel2024 = () => {
  return (
    <img className='img-home' src={ofijoelayala2024} alt="" />
  )
}
const ExcursionTlacotlapico = () => {
  return (
    <img className='img-home' src={excursionTlaco} alt="" />
  )
}
const DistribucionPlazas2024 = () => {
  return (
    <img className='img-home' src={distribucionplazas} alt="" />
  )
}
const NuevosCostosAcapulco = () => {
  return (
    <img className='img-home' src={nuevoscostosacapulco} alt="" />
  )
}
const ObituarioFeb2024 = () => {
  return (
    <img className='img-home' src={obituario2024} alt="" />
  )
}
const RespuestaAmparoCGT = () => {
  return (
    <img className='img-home' src={resamparo} alt="" />
  )
}
const Marzo18 = () => {
  return (
    <img className='img-home' src={marzo18} alt="" />
  )
}
const RiesgoTrabajo = () => {
  return (
    <img className='img-home' src={riesgotrabajo} alt="" />
  )
}
const ExcursionAcapulco = () => {
  return (
    <img className='img-home' src={excurAcapulco} alt="" />
  )
}
const ConvocatoriaSecretario = () => {
  return (
    <img className='img-home' src={convocatoriaSecretario} alt="" />
  )
}
const CambioAseguradora = () => {
  return (
    <img className='img-home' src={cambioaseguradora} alt="" />
  )
}
const Agroasemex = () => {
  return (
    <img className='img-home' src={agroasemex} alt="" />
  )
}
const SemSalud = () => {
  return (
    <img className='img-home' src={semsalud} alt="" />
  )
}
const Mayo1o = () => {
  return (
    <img className='img-home' src={mayo1o} alt="" />
  )
}
const EsquelaSaraMontiel = () => {
  return (
    <img className='img-home' src={esquelaSara} alt="" />
  )
}
const AcuerdosFSTSE2024 = () => {
  return (
    <img className='img-home' src={acuerdosFstse2024} alt="" />
  )
}
const ConvocatoriaCongreso2024 = () => {
  return (
    <img className='img-home' src={convCongr2024} alt="" />
  )
}
const FormatoPonencias2024 = () => {
  return (
    <img className='img-home' src={formPonecias} alt="" />
  )
}
const ExcurChina26Octubre = () => {
  return (
    <img className='img-home' src={excChinahuapan26Oct} alt="" />
  )
}
const Octubre1o = () => {
  return (
    <img className='img-home' src={octubre1o} alt="" />
  )
}

export {

  Octubre1o,
  ExcurChina26Octubre,
  FormatoPonencias2024,
  ConvocatoriaCongreso2024,
  AcuerdosFSTSE2024,
  EsquelaSaraMontiel,
  Mayo1o,
  SemSalud,
  Agroasemex,
  CambioAseguradora,
  ConvocatoriaSecretario,
  ExcursionAcapulco,
  RiesgoTrabajo,
  Marzo18,
  RespuestaAmparoCGT,
  ObituarioFeb2024,
  NuevosCostosAcapulco,
  DistribucionPlazas2024,
  ExcursionTlacotlapico,
  OficioPeticionJoel2024,
  Febrero05,
  RolVacacional2024,
  Enero1o,
  Diciembre25,
  SinAdornoElect,
  SolicStand,
  RespHerenciaPlazas,
  SolHerenciaPlazas,
  SeparacionSindicalizados,
  FloresRan,
  FloresAzafran,
  CarreraNOv23,
  EsquelaNov23,
  SolCempa,
  FloresOct23,
  OficioReyes23,
  ConvCala2023,
  Cala23,
  Condi2023,
  ComImposicion,
  ConvCalyOfre23,
  ExcursionNov2023,
  UniformTec23,
  ComCondEsca,
  PremioAntig23,
  RemAñosServ23,
  Escalafon2023,
  LineamientosCredencial,
  LaJornada2407,
  HaciendaTab,
  AcuerdosFtse,
  ConvCongreso,
  ConvConsejo,
  Lentes,
  Transporte,
  Rol,
  Telefonos,
  Objecion,
  Acuerdos,
  Asamblea
};