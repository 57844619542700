import PhotoGallery from "../components/PhotoGallery";
import exaca01 from '../assets/02_galeria/57_excur_aca_24/1.avif';
import exaca02 from '../assets/02_galeria/57_excur_aca_24/2.avif';
import exaca03 from '../assets/02_galeria/57_excur_aca_24/3.avif';
import exaca04 from '../assets/02_galeria/57_excur_aca_24/4.avif';
import exaca05 from '../assets/02_galeria/57_excur_aca_24/5.avif';
import exaca06 from '../assets/02_galeria/57_excur_aca_24/6.avif';
import exaca07 from '../assets/02_galeria/57_excur_aca_24/7.avif';
import exaca08 from '../assets/02_galeria/57_excur_aca_24/8.avif';
import exaca09 from '../assets/02_galeria/57_excur_aca_24/9.avif';
import exaca10 from '../assets/02_galeria/57_excur_aca_24/10.avif';
import exaca11 from '../assets/02_galeria/57_excur_aca_24/11.avif';
import exaca12 from '../assets/02_galeria/57_excur_aca_24/12.avif';
import exaca13 from '../assets/02_galeria/57_excur_aca_24/13.avif';
import exaca14 from '../assets/02_galeria/57_excur_aca_24/14.avif';
import exaca15 from '../assets/02_galeria/57_excur_aca_24/15.avif';
import exaca16 from '../assets/02_galeria/57_excur_aca_24/16.avif';
import exaca17 from '../assets/02_galeria/57_excur_aca_24/17.avif';
import exaca18 from '../assets/02_galeria/57_excur_aca_24/18.avif';
import exaca19 from '../assets/02_galeria/57_excur_aca_24/19.avif';

const ExcursionAcapulco2024 = () => {
  const galleryImages = [

    { img: exaca01 }, { img: exaca02 }, { img: exaca03 }, { img: exaca04 }, { img: exaca05 }, { img: exaca06 }, { img: exaca07 }, { img: exaca08 }, { img: exaca09 }, { img: exaca10 }, { img: exaca11 }, { img: exaca12 }, { img: exaca13 }, { img: exaca14 }, { img: exaca15 }, { img: exaca16 }, { img: exaca17 }, { img: exaca18 }, { img: exaca19 },

  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Excursión al puerto de Acapulco en el Estado de Guerrero <br /> (del 14 al 18 de junio de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default ExcursionAcapulco2024