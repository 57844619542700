import { BrowserRouter, Routes, Route } from "react-router-dom";
import './styles/App.css';
import Home from './pages/Home'
import Comunicados from "./pages/Comunicados";
import Galeria from "./pages/Galeria";
import Directorio from "./pages/Directorio";
import PDF from "./pages/PDF";
import Contacto from "./pages/Contacto";
import Nosotros from "./pages/Nosotros";



function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/comunicados" element={<Comunicados />} />
          <Route path="/multimedia" element={<Galeria />} />
          <Route path="/directorio" element={<Directorio />} />
          <Route path="/documentos" element={<PDF />} />
          <Route path="/nosotros" element={<Nosotros />} />
          <Route path="/contacto" element={<Contacto />} />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
