import "../styles/Tittle.css"
import logo from '../assets/sedatu.avif'
const Tittle = () => {
  return (
    <section className="container-tittle">
      <img src={logo} alt="logo" className="logo-snt" />
      <div className="tittle">
        <p className="tittlePrincipal">Sindicato Nacional de Trabajadores</p>
        <p className="tittleSecondary">de la Secretaría de Desarrollo Agrario, Territorial y Urbano </p>
      </div>

    </section>

  )
}

export default Tittle;