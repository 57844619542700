import PhotoGallery from "../components/PhotoGallery";
import elecaga01 from '../assets/02_galeria/22_elecciones_aga/01.avif';
import elecaga02 from '../assets/02_galeria/22_elecciones_aga/02.avif';
import elecaga03 from '../assets/02_galeria/22_elecciones_aga/03.avif';
import elecaga04 from '../assets/02_galeria/22_elecciones_aga/04.avif';
import elecaga05 from '../assets/02_galeria/22_elecciones_aga/05.avif';

const EleccionesAGA = () => {
  const galleryImages = [
    { img: elecaga01 }, { img: elecaga02 }, { img: elecaga03 }, { img: elecaga04 }, { img: elecaga05 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión con la delegación del Archivo General Agrario para sacar convocatoria para elección de nuevo delegado</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default EleccionesAGA