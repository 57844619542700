import PhotoGallery from "../components/PhotoGallery";
import misaaza01 from '../assets/02_galeria/31_misa_azafran/01.avif';
import misaaza02 from '../assets/02_galeria/31_misa_azafran/02.avif';
import misaaza03 from '../assets/02_galeria/31_misa_azafran/03.avif';
import misaaza04 from '../assets/02_galeria/31_misa_azafran/04.avif';
import misaaza05 from '../assets/02_galeria/31_misa_azafran/05.avif';
import misaaza06 from '../assets/02_galeria/31_misa_azafran/06.avif';
import misaaza07 from '../assets/02_galeria/31_misa_azafran/07.avif';
import misaaza08 from '../assets/02_galeria/31_misa_azafran/08.avif';
import misaaza09 from '../assets/02_galeria/31_misa_azafran/09.avif';
import misaaza10 from '../assets/02_galeria/31_misa_azafran/10.avif';
import misaaza11 from '../assets/02_galeria/31_misa_azafran/11.avif';
import misaaza12 from '../assets/02_galeria/31_misa_azafran/12.avif';
import misaaza13 from '../assets/02_galeria/31_misa_azafran/13.avif';
import misaaza14 from '../assets/02_galeria/31_misa_azafran/14.avif';
import misaaza15 from '../assets/02_galeria/31_misa_azafran/15.avif';
import misaaza16 from '../assets/02_galeria/31_misa_azafran/16.avif';
import misaaza17 from '../assets/02_galeria/31_misa_azafran/17.avif';
import misaaza18 from '../assets/02_galeria/31_misa_azafran/18.avif';
import misaaza19 from '../assets/02_galeria/31_misa_azafran/19.avif';
import misaaza20 from '../assets/02_galeria/31_misa_azafran/20.avif';
import misaaza21 from '../assets/02_galeria/31_misa_azafran/21.avif';
import misaaza22 from '../assets/02_galeria/31_misa_azafran/22.avif';
import misaaza23 from '../assets/02_galeria/31_misa_azafran/23.avif';
import misaaza24 from '../assets/02_galeria/31_misa_azafran/24.avif';
import misaaza25 from '../assets/02_galeria/31_misa_azafran/25.avif';
import misaaza26 from '../assets/02_galeria/31_misa_azafran/26.avif';
import misaaza27 from '../assets/02_galeria/31_misa_azafran/27.avif';
import misaaza28 from '../assets/02_galeria/31_misa_azafran/28.avif';
import misaaza29 from '../assets/02_galeria/31_misa_azafran/29.avif';

const MisaAzafran2023 = () => {
  const galleryImages = [
    { img: misaaza01 }, { img: misaaza02 }, { img: misaaza03 }, { img: misaaza04 }, { img: misaaza05 }, { img: misaaza06 }, { img: misaaza07 }, { img: misaaza08 }, { img: misaaza09 }, { img: misaaza10 }, { img: misaaza11 }, { img: misaaza12 }, { img: misaaza13 }, { img: misaaza14 }, { img: misaaza15 }, { img: misaaza16 }, { img: misaaza17 }, { img: misaaza18 }, { img: misaaza19 }, { img: misaaza20 }, { img: misaaza21 }, { img: misaaza22 }, { img: misaaza23 }, { img: misaaza24 }, { img: misaaza25 }, { img: misaaza26 }, { img: misaaza27 }, { img: misaaza28 }, { img: misaaza29 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Misa celebrada en Azafrán por el 12 de diciembre</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default MisaAzafran2023