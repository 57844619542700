import PhotoGallery from "../components/PhotoGallery";
import ampyexp01 from '../assets/02_galeria/25_expulsion_ignacio/01.avif';
import ampyexp02 from '../assets/02_galeria/25_expulsion_ignacio/02.avif';
import ampyexp03 from '../assets/02_galeria/25_expulsion_ignacio/03.avif';
import ampyexp04 from '../assets/02_galeria/25_expulsion_ignacio/04.avif';
import ampyexp05 from '../assets/02_galeria/25_expulsion_ignacio/05.avif';

const RespuestaYexpulsion = () => {
  const galleryImages = [
    { img: ampyexp01 }, { img: ampyexp02 }, { img: ampyexp03 }, { img: ampyexp04 }, { img: ampyexp05 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión informativa con delegados: el 04 de diciembre se recibe respuesta sobre el amparo interpuesto y se comunica la decisión de expulsar del Sindicato al delegado Ignacio y al compañero Moisés</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default RespuestaYexpulsion