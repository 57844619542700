import PhotoGallery from "../components/PhotoGallery";
import reudelsecre01 from '../assets/02_galeria/52_reunion_delegados_secretario/1.avif';
import reudelsecre02 from '../assets/02_galeria/52_reunion_delegados_secretario/2.avif';
import reudelsecre03 from '../assets/02_galeria/52_reunion_delegados_secretario/3.avif';
import reudelsecre04 from '../assets/02_galeria/52_reunion_delegados_secretario/4.avif';
import reudelsecre05 from '../assets/02_galeria/52_reunion_delegados_secretario/5.avif';
import reudelsecre06 from '../assets/02_galeria/52_reunion_delegados_secretario/6.avif';

const ReunionDelegadosSecretario = () => {
  const galleryImages = [

    { img: reudelsecre01 }, { img: reudelsecre02 }, { img: reudelsecre03 }, { img: reudelsecre04 }, { img: reudelsecre05 }, { img: reudelsecre06 },

  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión con Delegados y Vocales para informar sobre la Convocatoria a Secretario(a) General del SNTSEDATU <br /> (26 de marzo de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default ReunionDelegadosSecretario