/* eslint-disable jsx-a11y/iframe-has-title */
import Tittle from '../components/Tittle'
import NavBarClose from '../components/NavBarClose'
import '../styles/PDF.css'
import GoToTop from '../components/GoToTop'
import { Condiciones2017, Condiciones2023, Constitucion, Estatutos2017, Estatutos2024, Etica, Inclusivo, Issste, LeyFederal, LeyGeneral, Percepciones } from '../images/ImagesHome'

const PDF = () => {
  return (
    <>
      <header className='setHeading'>
        <Tittle />
        <NavBarClose />
      </header>
      <main className='setMain'>
        <section className='containerPdf'>
          <div className='formPdf'>
            <p className='titlePdf'>Estatutos 2024 <br /></p>
            <a href="https://drive.google.com/file/d/1Du06ml-Bh-khF19e22_S_kGlTGckhTqx" target='blank'><Estatutos2024 /></a>
          </div>
          <div className='formPdf'>
            <p className='titlePdf'>Condiciones Generales <br /> de Trabajo 2023</p>
            <a href="https://drive.google.com/file/d/1qmop0kJXxKJ-QMpOLO7c66duE3q3SjIS" target='blank'><Condiciones2023 /></a>
          </div>
          <div className='formPdf'>
            <p className='titlePdf'>Condiciones Generales <br /> de Trabajo 2017</p>
            <a href="https://drive.google.com/file/d/1n3IsTdXLeXcI-Cz8g5kT96g3B3oFXHIj" target='blank'><Condiciones2017 /></a>
          </div>
          <div className='formPdf'>
            <p className='titlePdf'><br />Estatutos 2017<br /></p>
            <a href="https://drive.google.com/file/d/1IUi43XN9vOVAsR_wBHygkQDliwe7DRW8" target='blank'><Estatutos2017 /></a>
          </div>
          <div className='formPdf'>
            <p className='titlePdf'>Constitución Política de los Estados Unidos Mexicanos</p>
            <a href="https://drive.google.com/file/d/1zsS87eYqsLa8_nTx9WUqhOv_-kqezfaK" target='blank'><Constitucion /></a>
          </div>
          <div className='formPdf'>
            <p className='titlePdf'><br /> Ley Federal de los Trabajadores <br />al Servicio del Estado</p>
            <a href="https://drive.google.com/file/d/12ueROVfkJhXL4QtH1ZnBnGGfYmP2lv1I" target='blank'><LeyFederal /></a>
          </div>
          <div className='formPdf'>
            <p className='titlePdf'><br /> Ley General de Responsabilidades Administrativas</p>
            <a href="https://drive.google.com/file/d/1upEBdL3MlPXTpQFVM9u9Z0fcncgoTmAM" target='blank'><LeyGeneral /></a>
          </div>
          <div className='formPdf'>
            <p className='titlePdf'><br /> Ley del Instituto de Seguridad y Servicios Sociales de los Trabajadores del Estado</p>
            <a href="https://drive.google.com/file/d/16jLKDp0dvYSnp5u-2yz7zXrQn14Ql5_p" target='blank'><Issste /></a>
          </div>
          <div className='formPdf'>
            <p className='titlePdf'>Manual de Percepciones de los Servidores Públicos de la Administración Pública Federal</p>
            <a href="https://drive.google.com/file/d/19-9GvtChIfU-Us--64IVJMEMC0X-tkSg" target='blank'><Percepciones /></a>
          </div>
          <div className='formPdf'>
            <p className='titlePdf'>Código de Ética de la Administración Pública Federal</p>
            <a href="https://drive.google.com/file/d/1cRt_o1R_0sGH1vO-rBqpa3xDvzAmFrn4" target='blank'><Etica /></a>
          </div>
          <div className='formPdf'>
            <p className='titlePdf'>Prontuario para el uso de lenguaje incluyene y no sexista</p>
            <a href="https://drive.google.com/file/d/1WuWsGI1h6OWpxi5fs4lkmZw3d2wfZrn3" target='blank'><Inclusivo /></a>
          </div>
        </section>

      </main>
      <GoToTop />
    </>
  )
}

export default PDF;