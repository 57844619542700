import PhotoGallery from "../components/PhotoGallery";
import reu2arevcon01 from '../assets/02_galeria/16_2a_rev_cond2023/1.avif';
import reu2arevcon02 from '../assets/02_galeria/16_2a_rev_cond2023/2.avif';
import reu2arevcon03 from '../assets/02_galeria/16_2a_rev_cond2023/3.avif';
import reu2arevcon04 from '../assets/02_galeria/16_2a_rev_cond2023/4.avif';
import reu2arevcon05 from '../assets/02_galeria/16_2a_rev_cond2023/5.avif';

const Reunnion2aRevCondiciones = () => {
  const galleryImages = [
    { img: reu2arevcon01 }, { img: reu2arevcon02 }, { img: reu2arevcon03 }, { img: reu2arevcon04 }, { img: reu2arevcon05 },
  ]
  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión con Delegados para una 2a revisión de las Condiciones Generales de Trabajo</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default Reunnion2aRevCondiciones