import PhotoGallery from "../components/PhotoGallery";
import votadel1901 from '../assets/02_galeria/32_votaciones_del19/01.avif';
import votadel1902 from '../assets/02_galeria/32_votaciones_del19/02.avif';
import votadel1903 from '../assets/02_galeria/32_votaciones_del19/03.avif';
import votadel1904 from '../assets/02_galeria/32_votaciones_del19/04.avif';
import votadel1905 from '../assets/02_galeria/32_votaciones_del19/05.avif';
import votadel1906 from '../assets/02_galeria/32_votaciones_del19/06.avif';
import votadel1907 from '../assets/02_galeria/32_votaciones_del19/07.avif';
import votadel1908 from '../assets/02_galeria/32_votaciones_del19/08.avif';

const VotacionesDeleg19 = () => {
  const galleryImages = [
    { img: votadel1901 }, { img: votadel1902 }, { img: votadel1903 }, { img: votadel1904 }, { img: votadel1905 }, { img: votadel1906 }, { img: votadel1907 }, { img: votadel1908 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión con la Delegación 19 para elección de Delegado y Vocales <br /> Las planillas no cubrieron todos los requisitos, se convocará de nuevo en enero (18 de diciembre de 2023)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default VotacionesDeleg19