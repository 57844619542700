import PhotoGallery from "../components/PhotoGallery";
import delegadosescalafon1 from '../assets/02_galeria/43_delegados_escalafon/1.avif';
import delegadosescalafon2 from '../assets/02_galeria/43_delegados_escalafon/2.avif';
import delegadosescalafon3 from '../assets/02_galeria/43_delegados_escalafon/3.avif';
import delegadosescalafon4 from '../assets/02_galeria/43_delegados_escalafon/4.avif';
import delegadosescalafon5 from '../assets/02_galeria/43_delegados_escalafon/5.avif';
import delegadosescalafon6 from '../assets/02_galeria/43_delegados_escalafon/6.avif';

const DelegadosPlazasEscalafon = () => {
  const galleryImages = [
    { img: delegadosescalafon1 }, { img: delegadosescalafon2 }, { img: delegadosescalafon3 }, { img: delegadosescalafon4 }, { img: delegadosescalafon5 }, { img: delegadosescalafon6 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión con delegados para informar la logística de distribución de plazas<br /> (08 de febrero de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default DelegadosPlazasEscalafon