import PhotoGallery from "../components/PhotoGallery";
import pensio01 from '../assets/02_galeria/24_pensionissste2023/01.avif';
import pensio02 from '../assets/02_galeria/24_pensionissste2023/02.avif';
import pensio03 from '../assets/02_galeria/24_pensionissste2023/03.avif';
import pensio04 from '../assets/02_galeria/24_pensionissste2023/04.avif';
import pensio05 from '../assets/02_galeria/24_pensionissste2023/05.avif';
import pensio06 from '../assets/02_galeria/24_pensionissste2023/06.avif';
import pensio07 from '../assets/02_galeria/24_pensionissste2023/07.avif';
import pensio08 from '../assets/02_galeria/24_pensionissste2023/08.avif';
import pensio09 from '../assets/02_galeria/24_pensionissste2023/09.avif';
import pensio10 from '../assets/02_galeria/24_pensionissste2023/10.avif';
import pensio11 from '../assets/02_galeria/24_pensionissste2023/11.avif';
import pensio12 from '../assets/02_galeria/24_pensionissste2023/12.avif';
import pensio13 from '../assets/02_galeria/24_pensionissste2023/13.avif';
import pensio14 from '../assets/02_galeria/24_pensionissste2023/14.avif';
import pensio15 from '../assets/02_galeria/24_pensionissste2023/15.avif';
import pensio16 from '../assets/02_galeria/24_pensionissste2023/16.avif';


const Pensionissste2023 = () => {
  const galleryImages = [
    { img: pensio01 }, { img: pensio02 }, { img: pensio03 }, { img: pensio04 }, { img: pensio05 }, { img: pensio06 }, { img: pensio07 }, { img: pensio08 }, { img: pensio09 }, { img: pensio10 }, { img: pensio11 }, { img: pensio12 }, { img: pensio13 }, { img: pensio14 }, { img: pensio15 }, { img: pensio16 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Plática informativa del personal del ISSSTE para hablar sobre la Afore Pensionissste y todos los trámites necesarios para la jubilación.</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default Pensionissste2023