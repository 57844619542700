import PhotoGallery from "../components/PhotoGallery";
import infoDel1901 from '../assets/02_galeria/28_info_del19/01.avif';
import infoDel1902 from '../assets/02_galeria/28_info_del19/02.avif';
import infoDel1903 from '../assets/02_galeria/28_info_del19/03.avif';
import infoDel1904 from '../assets/02_galeria/28_info_del19/04.avif';
import infoDel1905 from '../assets/02_galeria/28_info_del19/05.avif';
import infoDel1906 from '../assets/02_galeria/28_info_del19/06.avif';
import infoDel1907 from '../assets/02_galeria/28_info_del19/07.avif';
import infoDel1908 from '../assets/02_galeria/28_info_del19/08.avif';
import infoDel1909 from '../assets/02_galeria/28_info_del19/09.avif';
import infoDel1910 from '../assets/02_galeria/28_info_del19/10.avif';


const InfoyConvocatoriaDel19 = () => {
  const galleryImages = [
    { img: infoDel1901 }, { img: infoDel1902 }, { img: infoDel1903 }, { img: infoDel1904 }, { img: infoDel1905 }, { img: infoDel1906 }, { img: infoDel1907 }, { img: infoDel1908 }, { img: infoDel1909 }, { img: infoDel1910 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión con el personal de la Delegación 19 para tratar asuntos generales y sacar convocatoria para nuevos representantes<br /> (05 de diciembre de 2023)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default InfoyConvocatoriaDel19