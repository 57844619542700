import PhotoGallery from "../components/PhotoGallery";
import infoissste012401 from '../assets/02_galeria/35_Info_issste_3_7_13/1.avif';
import infoissste012402 from '../assets/02_galeria/35_Info_issste_3_7_13/2.avif';
import infoissste012403 from '../assets/02_galeria/35_Info_issste_3_7_13/3.avif';
import infoissste012404 from '../assets/02_galeria/35_Info_issste_3_7_13/4.avif';
import infoissste012405 from '../assets/02_galeria/35_Info_issste_3_7_13/5.avif';
import infoissste012406 from '../assets/02_galeria/35_Info_issste_3_7_13/6.avif';
import infoissste012407 from '../assets/02_galeria/35_Info_issste_3_7_13/7.avif';


const InfoISSSTEdel030713 = () => {
  const galleryImages = [
    { img: infoissste012401 }, { img: infoissste012402 }, { img: infoissste012403 }, { img: infoissste012404 }, { img: infoissste012405 }, { img: infoissste012406 }, { img: infoissste012407 }
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión de personal del ISSSTE con las Delegaciones 03, 07 y 13 para dar información sobre Jubilación y Afore<br /> (16 de enero de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default InfoISSSTEdel030713