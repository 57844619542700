import PhotoGallery from "../components/PhotoGallery";
import rosca01 from '../assets/02_galeria/34_reyes24/1.avif';
import rosca02 from '../assets/02_galeria/34_reyes24/2.avif';
import rosca03 from '../assets/02_galeria/34_reyes24/3.avif';
import rosca04 from '../assets/02_galeria/34_reyes24/4.avif';
import rosca05 from '../assets/02_galeria/34_reyes24/5.avif';


const Reunion01yRosca = () => {
  const galleryImages = [
    { img: rosca01 }, { img: rosca02 }, { img: rosca03 }, { img: rosca04 }, { img: rosca05 }
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">1a Reunión de trabajo con Delegados <br /> (09 de enero de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default Reunion01yRosca