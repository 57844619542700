import PhotoGallery from "../components/PhotoGallery";
import reudel1101 from '../assets/02_galeria/12_reu_del_sep23/reuDel1109_01.avif'
import reudel1102 from '../assets/02_galeria/12_reu_del_sep23/reuDel1109_02.avif'
import reudel1103 from '../assets/02_galeria/12_reu_del_sep23/reuDel1109_03.avif'
import reudel1104 from '../assets/02_galeria/12_reu_del_sep23/reuDel1109_04.avif'
import reudel1105 from '../assets/02_galeria/12_reu_del_sep23/reuDel1109_05.avif'


const ReuDel11sep23 = () => {
  const galleryImages = [
    { img: reudel1101 }, { img: reudel1102 }, { img: reudel1103 }, { img: reudel1104 }, { img: reudel1105 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión con Delegados para tratar la nivelación de la base trabajadora y las Condiciones Generales de Trabajo <br /> (11 de septiembre de 2023)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default ReuDel11sep23