import PhotoGallery from "../components/PhotoGallery";
import ES01 from "../assets/02_galeria/03_servicio/01.avif";
import ES02 from "../assets/02_galeria/03_servicio/02.avif";
import ES03 from "../assets/02_galeria/03_servicio/03.avif";
import ES04 from "../assets/02_galeria/03_servicio/04.avif";
import ES05 from "../assets/02_galeria/03_servicio/05.avif";
import ES06 from "../assets/02_galeria/03_servicio/06.avif";
import ES07 from "../assets/02_galeria/03_servicio/07.avif";
import ES08 from "../assets/02_galeria/03_servicio/08.avif";
import ES09 from "../assets/02_galeria/03_servicio/09.avif";
import ES10 from "../assets/02_galeria/03_servicio/10.avif";
import ES11 from "../assets/02_galeria/03_servicio/11.avif";
import ES12 from "../assets/02_galeria/03_servicio/12.avif";
import ES13 from "../assets/02_galeria/03_servicio/13.avif";
import ES14 from "../assets/02_galeria/03_servicio/14.avif";
import ES15 from "../assets/02_galeria/03_servicio/15.avif";
import ES16 from "../assets/02_galeria/03_servicio/16.avif";
import ES17 from "../assets/02_galeria/03_servicio/17.avif";
import ES18 from "../assets/02_galeria/03_servicio/18.avif";
import ES19 from "../assets/02_galeria/03_servicio/19.avif";
import ES20 from "../assets/02_galeria/03_servicio/20.avif";
import ES21 from "../assets/02_galeria/03_servicio/21.avif";
import ES22 from "../assets/02_galeria/03_servicio/22.avif";
import ES23 from "../assets/02_galeria/03_servicio/23.avif";
import ES24 from "../assets/02_galeria/03_servicio/24.avif";
import ES25 from "../assets/02_galeria/03_servicio/25.avif";
import ES26 from "../assets/02_galeria/03_servicio/26.avif";


const AñosServicio2022 = () => {
  const galleryImages = [

    { img: ES01 }, { img: ES02 }, { img: ES03 }, { img: ES04 }, { img: ES05 }, { img: ES06 }, { img: ES07 }, { img: ES08 }, { img: ES09 }, { img: ES10 }, { img: ES11 }, { img: ES12 }, { img: ES13 }, { img: ES14 }, { img: ES15 }, { img: ES16 }, { img: ES17 }, { img: ES18 }, { img: ES19 }, { img: ES20 }, { img: ES21 }, { img: ES22 }, { img: ES23 }, { img: ES24 }, { img: ES25 }, { img: ES26 },

  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Ceremonia de Reconocimiento por Años de Servicio<br />Llevada a cabo el 27 de junio de 2023 en el Ex Convento de San Hipolito</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default AñosServicio2022