import PhotoGallery from "../components/PhotoGallery";
import B101 from '../assets/02_galeria/04_asam_cong_23/bloque-1/01.avif';
import B102 from '../assets/02_galeria/04_asam_cong_23/bloque-1/02.avif';
import B103 from '../assets/02_galeria/04_asam_cong_23/bloque-1/03.avif';
import B104 from '../assets/02_galeria/04_asam_cong_23/bloque-1/04.avif';
import B105 from '../assets/02_galeria/04_asam_cong_23/bloque-1/05.avif';
import B106 from '../assets/02_galeria/04_asam_cong_23/bloque-1/06.avif';
import B107 from '../assets/02_galeria/04_asam_cong_23/bloque-1/07.avif';
import B108 from '../assets/02_galeria/04_asam_cong_23/bloque-1/08.avif';
import B109 from '../assets/02_galeria/04_asam_cong_23/bloque-1/09.avif';
import B110 from '../assets/02_galeria/04_asam_cong_23/bloque-1/10.avif';
import B111 from '../assets/02_galeria/04_asam_cong_23/bloque-1/11.avif';
import B112 from '../assets/02_galeria/04_asam_cong_23/bloque-1/12.avif';
import B113 from '../assets/02_galeria/04_asam_cong_23/bloque-1/13.avif';
import B114 from '../assets/02_galeria/04_asam_cong_23/bloque-1/14.avif';
import B115 from '../assets/02_galeria/04_asam_cong_23/bloque-1/15.avif';
import B201 from '../assets/02_galeria/04_asam_cong_23/bloque-2/01.avif';
import B202 from '../assets/02_galeria/04_asam_cong_23/bloque-2/02.avif';
import B203 from '../assets/02_galeria/04_asam_cong_23/bloque-2/03.avif';
import B204 from '../assets/02_galeria/04_asam_cong_23/bloque-2/04.avif';
import B205 from '../assets/02_galeria/04_asam_cong_23/bloque-2/05.avif';
import B206 from '../assets/02_galeria/04_asam_cong_23/bloque-2/06.avif';
import B208 from '../assets/02_galeria/04_asam_cong_23/bloque-2/08.avif';
import B209 from '../assets/02_galeria/04_asam_cong_23/bloque-2/09.avif';
import B210 from '../assets/02_galeria/04_asam_cong_23/bloque-2/10.avif';
import B211 from '../assets/02_galeria/04_asam_cong_23/bloque-2/11.avif';
import B212 from '../assets/02_galeria/04_asam_cong_23/bloque-2/12.avif';
import B213 from '../assets/02_galeria/04_asam_cong_23/bloque-2/13.avif';
import B214 from '../assets/02_galeria/04_asam_cong_23/bloque-2/14.avif';
import B215 from '../assets/02_galeria/04_asam_cong_23/bloque-2/15.avif';
import B216 from '../assets/02_galeria/04_asam_cong_23/bloque-2/16.avif';
import B217 from '../assets/02_galeria/04_asam_cong_23/bloque-2/17.avif';
import B218 from '../assets/02_galeria/04_asam_cong_23/bloque-2/18.avif';
import B219 from '../assets/02_galeria/04_asam_cong_23/bloque-2/19.avif';
import B220 from '../assets/02_galeria/04_asam_cong_23/bloque-2/20.avif';
import B221 from '../assets/02_galeria/04_asam_cong_23/bloque-2/21.avif';
import B222 from '../assets/02_galeria/04_asam_cong_23/bloque-2/22.avif';
import B223 from '../assets/02_galeria/04_asam_cong_23/bloque-2/23.avif';
import B224 from '../assets/02_galeria/04_asam_cong_23/bloque-2/24.avif';
import B225 from '../assets/02_galeria/04_asam_cong_23/bloque-2/25.avif';
import B226 from '../assets/02_galeria/04_asam_cong_23/bloque-2/26.avif';
import B227 from '../assets/02_galeria/04_asam_cong_23/bloque-2/27.avif';
import B228 from '../assets/02_galeria/04_asam_cong_23/bloque-2/28.avif';
import B229 from '../assets/02_galeria/04_asam_cong_23/bloque-2/29.avif';
import B230 from '../assets/02_galeria/04_asam_cong_23/bloque-2/30.avif';
import B301 from '../assets/02_galeria/04_asam_cong_23/bloque-3/01.avif';
import B302 from '../assets/02_galeria/04_asam_cong_23/bloque-3/02.avif';
import B303 from '../assets/02_galeria/04_asam_cong_23/bloque-3/03.avif';
import B304 from '../assets/02_galeria/04_asam_cong_23/bloque-3/04.avif';
import B305 from '../assets/02_galeria/04_asam_cong_23/bloque-3/05.avif';
import B306 from '../assets/02_galeria/04_asam_cong_23/bloque-3/06.avif';
import B307 from '../assets/02_galeria/04_asam_cong_23/bloque-3/07.avif';
import B308 from '../assets/02_galeria/04_asam_cong_23/bloque-3/08.avif';
import B309 from '../assets/02_galeria/04_asam_cong_23/bloque-3/09.avif';
import B310 from '../assets/02_galeria/04_asam_cong_23/bloque-3/10.avif';
import B311 from '../assets/02_galeria/04_asam_cong_23/bloque-3/11.avif';
import B312 from '../assets/02_galeria/04_asam_cong_23/bloque-3/12.avif';
import B313 from '../assets/02_galeria/04_asam_cong_23/bloque-3/13.avif';
import B314 from '../assets/02_galeria/04_asam_cong_23/bloque-3/14.avif';
import B315 from '../assets/02_galeria/04_asam_cong_23/bloque-3/15.avif';
import B316 from '../assets/02_galeria/04_asam_cong_23/bloque-3/16.avif';
import B317 from '../assets/02_galeria/04_asam_cong_23/bloque-3/17.avif';
import B318 from '../assets/02_galeria/04_asam_cong_23/bloque-3/18.avif';
import B319 from '../assets/02_galeria/04_asam_cong_23/bloque-3/19.avif';
import B320 from '../assets/02_galeria/04_asam_cong_23/bloque-3/20.avif';
import B321 from '../assets/02_galeria/04_asam_cong_23/bloque-3/21.avif';
import B322 from '../assets/02_galeria/04_asam_cong_23/bloque-3/22.avif';
import B401 from '../assets/02_galeria/04_asam_cong_23/bloque-4/01.avif';
import B402 from '../assets/02_galeria/04_asam_cong_23/bloque-4/02.avif';
import B403 from '../assets/02_galeria/04_asam_cong_23/bloque-4/03.avif';
import B404 from '../assets/02_galeria/04_asam_cong_23/bloque-4/04.avif';
import B405 from '../assets/02_galeria/04_asam_cong_23/bloque-4/05.avif';
import B406 from '../assets/02_galeria/04_asam_cong_23/bloque-4/06.avif';
import B407 from '../assets/02_galeria/04_asam_cong_23/bloque-4/07.avif';
import B408 from '../assets/02_galeria/04_asam_cong_23/bloque-4/08.avif';
import B409 from '../assets/02_galeria/04_asam_cong_23/bloque-4/09.avif';
import B410 from '../assets/02_galeria/04_asam_cong_23/bloque-4/10.avif';
import B501 from '../assets/02_galeria/04_asam_cong_23/bloque-5/01.avif';
import B502 from '../assets/02_galeria/04_asam_cong_23/bloque-5/02.avif';
import B503 from '../assets/02_galeria/04_asam_cong_23/bloque-5/03.avif';
import B504 from '../assets/02_galeria/04_asam_cong_23/bloque-5/04.avif';
import B505 from '../assets/02_galeria/04_asam_cong_23/bloque-5/05.avif';
import B506 from '../assets/02_galeria/04_asam_cong_23/bloque-5/06.avif';
import B507 from '../assets/02_galeria/04_asam_cong_23/bloque-5/07.avif';
import B508 from '../assets/02_galeria/04_asam_cong_23/bloque-5/08.avif';
import B509 from '../assets/02_galeria/04_asam_cong_23/bloque-5/09.avif';

const AsamGralCong23 = () => {
  const galleryImages = [

    { img: B101 }, { img: B102 }, { img: B103 }, { img: B104 }, { img: B105 }, { img: B106 }, { img: B107 }, { img: B108 }, { img: B109 }, { img: B110 }, { img: B111 }, { img: B112 }, { img: B113 }, { img: B114 }, { img: B115 }, { img: B201 }, { img: B202 }, { img: B203 }, { img: B204 }, { img: B205 }, { img: B206 }, { img: B208 }, { img: B209 }, { img: B210 }, { img: B211 }, { img: B212 }, { img: B213 }, { img: B214 }, { img: B215 }, { img: B216 }, { img: B217 }, { img: B218 }, { img: B219 }, { img: B220 }, { img: B221 }, { img: B222 }, { img: B223 }, { img: B224 }, { img: B225 }, { img: B226 }, { img: B227 }, { img: B228 }, { img: B229 }, { img: B230 }, { img: B301 }, { img: B302 }, { img: B303 }, { img: B304 }, { img: B305 }, { img: B306 }, { img: B307 }, { img: B308 }, { img: B309 }, { img: B310 }, { img: B311 }, { img: B312 }, { img: B313 }, { img: B314 }, { img: B315 }, { img: B316 }, { img: B317 }, { img: B318 }, { img: B319 }, { img: B320 }, { img: B321 }, { img: B322 }, { img: B401 }, { img: B402 }, { img: B403 }, { img: B404 }, { img: B405 }, { img: B406 }, { img: B407 }, { img: B408 }, { img: B409 }, { img: B410 }, { img: B501 }, { img: B502 }, { img: B503 }, { img: B504 }, { img: B505 }, { img: B506 }, { img: B507 }, { img: B508 }, { img: B509 }

  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Asambleas Generales a Nivel Nacional para la eleccion de Representantes al <br />XXXIII Consejo Nacional Ordinario y XVIII Congreso Nacional Extraordinario</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default AsamGralCong23