import PhotoGallery from "../components/PhotoGallery";
import dañosaca01 from '../assets/02_galeria/19_daños_acapulco/01.avif';
import dañosaca02 from '../assets/02_galeria/19_daños_acapulco/02.avif';
import dañosaca03 from '../assets/02_galeria/19_daños_acapulco/03.avif';
import dañosaca04 from '../assets/02_galeria/19_daños_acapulco/04.avif';
import dañosaca05 from '../assets/02_galeria/19_daños_acapulco/05.avif';
import dañosaca06 from '../assets/02_galeria/19_daños_acapulco/06.avif';
import dañosaca07 from '../assets/02_galeria/19_daños_acapulco/07.avif';
import dañosaca08 from '../assets/02_galeria/19_daños_acapulco/08.avif';
import dañosaca09 from '../assets/02_galeria/19_daños_acapulco/09.avif';
import dañosaca10 from '../assets/02_galeria/19_daños_acapulco/10.avif';
import dañosaca11 from '../assets/02_galeria/19_daños_acapulco/11.avif';
import dañosaca12 from '../assets/02_galeria/19_daños_acapulco/12.avif';
import dañosaca13 from '../assets/02_galeria/19_daños_acapulco/13.avif';
import dañosaca14 from '../assets/02_galeria/19_daños_acapulco/14.avif';
import dañosaca15 from '../assets/02_galeria/19_daños_acapulco/15.avif';
import dañosaca16 from '../assets/02_galeria/19_daños_acapulco/16.avif';
import dañosaca17 from '../assets/02_galeria/19_daños_acapulco/17.avif';
import dañosaca18 from '../assets/02_galeria/19_daños_acapulco/18.avif';
import dañosaca19 from '../assets/02_galeria/19_daños_acapulco/19.avif';
import dañosaca20 from '../assets/02_galeria/19_daños_acapulco/20.avif';
import dañosaca21 from '../assets/02_galeria/19_daños_acapulco/21.avif';
import dañosaca22 from '../assets/02_galeria/19_daños_acapulco/22.avif';
import dañosaca23 from '../assets/02_galeria/19_daños_acapulco/23.avif';
import dañosaca24 from '../assets/02_galeria/19_daños_acapulco/24.avif';
import dañosaca25 from '../assets/02_galeria/19_daños_acapulco/25.avif';
import dañosaca26 from '../assets/02_galeria/19_daños_acapulco/26.avif';
import dañosaca27 from '../assets/02_galeria/19_daños_acapulco/27.avif';
import dañosaca28 from '../assets/02_galeria/19_daños_acapulco/28.avif';
import dañosaca29 from '../assets/02_galeria/19_daños_acapulco/29.avif';
import dañosaca30 from '../assets/02_galeria/19_daños_acapulco/30.avif';
import dañosaca31 from '../assets/02_galeria/19_daños_acapulco/31.avif';
import dañosaca32 from '../assets/02_galeria/19_daños_acapulco/32.avif';
import dañosaca33 from '../assets/02_galeria/19_daños_acapulco/33.avif';
import dañosaca34 from '../assets/02_galeria/19_daños_acapulco/34.avif';
import dañosaca35 from '../assets/02_galeria/19_daños_acapulco/35.avif';
import dañosaca36 from '../assets/02_galeria/19_daños_acapulco/36.avif';
import dañosaca37 from '../assets/02_galeria/19_daños_acapulco/37.avif';
import dañosaca38 from '../assets/02_galeria/19_daños_acapulco/38.avif';
import dañosaca39 from '../assets/02_galeria/19_daños_acapulco/39.avif';
import dañosaca40 from '../assets/02_galeria/19_daños_acapulco/40.avif';


const DañosCasaAcapulco = () => {
  const galleryImages = [
    { img: dañosaca01 }, { img: dañosaca02 }, { img: dañosaca03 }, { img: dañosaca04 }, { img: dañosaca05 }, { img: dañosaca06 }, { img: dañosaca07 }, { img: dañosaca08 }, { img: dañosaca09 }, { img: dañosaca10 }, { img: dañosaca11 }, { img: dañosaca12 }, { img: dañosaca13 }, { img: dañosaca14 }, { img: dañosaca15 }, { img: dañosaca16 }, { img: dañosaca17 }, { img: dañosaca18 }, { img: dañosaca19 }, { img: dañosaca20 }, { img: dañosaca21 }, { img: dañosaca22 }, { img: dañosaca23 }, { img: dañosaca24 }, { img: dañosaca25 }, { img: dañosaca26 }, { img: dañosaca27 }, { img: dañosaca28 }, { img: dañosaca29 }, { img: dañosaca30 }, { img: dañosaca31 }, { img: dañosaca32 }, { img: dañosaca33 }, { img: dañosaca34 }, { img: dañosaca35 }, { img: dañosaca36 }, { img: dañosaca37 }, { img: dañosaca38 }, { img: dañosaca39 }, { img: dañosaca40 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Daños a la casa vacacional perteneciente al SNTSEDATU en Acapulco, Guerrero, por el paso del huracán Otis</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default DañosCasaAcapulco