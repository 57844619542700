import PhotoGallery from "../components/PhotoGallery";
import reunionaga2401 from '../assets/02_galeria/36_reunion_aga/1.avif';
import reunionaga2402 from '../assets/02_galeria/36_reunion_aga/2.avif';
import reunionaga2403 from '../assets/02_galeria/36_reunion_aga/3.avif';
import reunionaga2404 from '../assets/02_galeria/36_reunion_aga/4.avif';

const ReunionAGA2024 = () => {
  const galleryImages = [
    { img: reunionaga2401 }, { img: reunionaga2402 }, { img: reunionaga2403 }, { img: reunionaga2404 }
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión con la delegación del Archivo General Agrario <br /> (16 de enero de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default ReunionAGA2024