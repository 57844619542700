/* eslint-disable jsx-a11y/iframe-has-title */
import PhotoGallery from "../components/PhotoGallery";
import AG01 from '../assets/02_galeria/02_asamblea/1.avif';
import AG02 from '../assets/02_galeria/02_asamblea/2.avif';
import AG03 from '../assets/02_galeria/02_asamblea/3.avif';
import AG04 from '../assets/02_galeria/02_asamblea/4.avif';
import AG05 from '../assets/02_galeria/02_asamblea/5.avif';
import AG06 from '../assets/02_galeria/02_asamblea/6.avif';
import AG07 from '../assets/02_galeria/02_asamblea/7.avif';
import AG08 from '../assets/02_galeria/02_asamblea/8.avif';
import AG09 from '../assets/02_galeria/02_asamblea/9.avif';
import AG10 from '../assets/02_galeria/02_asamblea/10.avif';
import AG11 from '../assets/02_galeria/02_asamblea/11.avif';
import AG12 from '../assets/02_galeria/02_asamblea/12.avif';
import AG13 from '../assets/02_galeria/02_asamblea/13.avif';
import AG14 from '../assets/02_galeria/02_asamblea/14.avif';
import AG15 from '../assets/02_galeria/02_asamblea/15.avif';
import AG16 from '../assets/02_galeria/02_asamblea/16.avif';
import AG17 from '../assets/02_galeria/02_asamblea/17.avif';
import AG18 from '../assets/02_galeria/02_asamblea/18.avif';
import AG19 from '../assets/02_galeria/02_asamblea/19.avif';
import AG20 from '../assets/02_galeria/02_asamblea/20.avif';
import AG21 from '../assets/02_galeria/02_asamblea/21.avif';
import AG22 from '../assets/02_galeria/02_asamblea/22.avif';
import AG23 from '../assets/02_galeria/02_asamblea/23.avif';

const AsamGral23 = () => {
  const galleryImages = [
    { img: AG01 }, { img: AG02 }, { img: AG03 }, { img: AG04 }, { img: AG05 }, { img: AG06 }, { img: AG07 }, { img: AG08 }, { img: AG09 }, { img: AG10 }, { img: AG11 }, { img: AG12 }, { img: AG13 }, { img: AG14 }, { img: AG15 }, { img: AG16 }, { img: AG17 }, { img: AG18 }, { img: AG19 }, { img: AG20 }, { img: AG21 }, { img: AG22 }, { img: AG23 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Asamblea Nacional General Permanente Extraordinaria <br />durante los mes de abril - junio 2023</p>
      <PhotoGallery galleryImages={galleryImages} />
      <div className="videosCont">
        <iframe src="https://drive.google.com/file/d/1ZWewHNvD9BqUUfGl4zxekpIIKegwqVUC/preview" className="singleVideo" />
        <iframe src="https://drive.google.com/file/d/1DfANtlWAzSuzdhdahlQ3DAXdp5NitC5W/preview" className="singleVideo" />
        <iframe src="https://drive.google.com/file/d/1s7X_mbwfNP6uz4GE6bBkPkNvqJ6PceBB/preview" className="singleVideo" />
        <iframe src="https://drive.google.com/file/d/1NXlGb0LT6QYHRzsyKiPWq52I94OTx29b/preview" className="singleVideo" />
        <iframe src="https://drive.google.com/file/d/1mpjFTsA0IJrRQRfOyqDCc3-KR27feYVA/preview" className="singleVideo" />
        <iframe src="https://drive.google.com/file/d/1vucCVs2jyzY4uz_4sTxhLdw60BIuPF2W/preview" className="singleVideo" />
        <iframe src="https://drive.google.com/file/d/1ZbvRwQgipdcO7HNHADFnXUGhB6HGKJ_C/preview" className="singleVideo" />
      </div>

    </div >
  );
}

export default AsamGral23

