import PhotoGallery from "../components/PhotoGallery";
import infoissste05sec1001 from '../assets/02_galeria/39_Info_issste_5_sec10/1.avif';
import infoissste05sec1002 from '../assets/02_galeria/39_Info_issste_5_sec10/2.avif';
import infoissste05sec1003 from '../assets/02_galeria/39_Info_issste_5_sec10/3.avif';
import infoissste05sec1004 from '../assets/02_galeria/39_Info_issste_5_sec10/4.avif';
import infoissste05sec1005 from '../assets/02_galeria/39_Info_issste_5_sec10/5.avif';
import infoissste05sec1006 from '../assets/02_galeria/39_Info_issste_5_sec10/6.avif';
import infoissste05sec1007 from '../assets/02_galeria/39_Info_issste_5_sec10/7.avif';


const InfoISSSTEdel05ySecc10 = () => {
  const galleryImages = [
    { img: infoissste05sec1001 }, { img: infoissste05sec1002 }, { img: infoissste05sec1003 }, { img: infoissste05sec1004 }, { img: infoissste05sec1005 }, { img: infoissste05sec1006 }, { img: infoissste05sec1007 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión de personal del ISSSTE con la Delegación 05 y Seccional 10 para dar información sobre Jubilación y Afore<br /> (18 de enero de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default InfoISSSTEdel05ySecc10