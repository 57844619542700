import PhotoGallery from "../components/PhotoGallery";
import C01 from '../assets/02_galeria/01_congreso_22/1.avif';
import C02 from '../assets/02_galeria/01_congreso_22/2.avif';
import C03 from '../assets/02_galeria/01_congreso_22/3.avif';
import C04 from '../assets/02_galeria/01_congreso_22/4.avif';
import C05 from '../assets/02_galeria/01_congreso_22/5.avif';
import C06 from '../assets/02_galeria/01_congreso_22/6.avif';
import C07 from '../assets/02_galeria/01_congreso_22/7.avif';
import C08 from '../assets/02_galeria/01_congreso_22/8.avif';
import C09 from '../assets/02_galeria/01_congreso_22/9.avif';
import C10 from '../assets/02_galeria/01_congreso_22/10.avif';
import C11 from '../assets/02_galeria/01_congreso_22/11.avif';
import C12 from '../assets/02_galeria/01_congreso_22/12.avif';
import C13 from '../assets/02_galeria/01_congreso_22/13.avif';
import C14 from '../assets/02_galeria/01_congreso_22/14.avif';
import C15 from '../assets/02_galeria/01_congreso_22/15.avif';
import C16 from '../assets/02_galeria/01_congreso_22/16.avif';
import C17 from '../assets/02_galeria/01_congreso_22/17.avif';
import C18 from '../assets/02_galeria/01_congreso_22/18.avif';
import C19 from '../assets/02_galeria/01_congreso_22/19.avif';
import C20 from '../assets/02_galeria/01_congreso_22/20.avif';
import C21 from '../assets/02_galeria/01_congreso_22/21.avif';
import C22 from '../assets/02_galeria/01_congreso_22/22.avif';
import C23 from '../assets/02_galeria/01_congreso_22/23.avif';
import C24 from '../assets/02_galeria/01_congreso_22/24.avif';
import C25 from '../assets/02_galeria/01_congreso_22/25.avif';
import C26 from '../assets/02_galeria/01_congreso_22/26.avif';
import C27 from '../assets/02_galeria/01_congreso_22/27.avif';
import C28 from '../assets/02_galeria/01_congreso_22/28.avif';
import C29 from '../assets/02_galeria/01_congreso_22/29.avif';
import C30 from '../assets/02_galeria/01_congreso_22/30.avif';
import C31 from '../assets/02_galeria/01_congreso_22/31.avif';
import C32 from '../assets/02_galeria/01_congreso_22/32.avif';
import C33 from '../assets/02_galeria/01_congreso_22/33.avif';
import C34 from '../assets/02_galeria/01_congreso_22/34.avif';
import C35 from '../assets/02_galeria/01_congreso_22/35.avif';
import C36 from '../assets/02_galeria/01_congreso_22/36.avif';
import C37 from '../assets/02_galeria/01_congreso_22/37.avif';
import C38 from '../assets/02_galeria/01_congreso_22/38.avif';
import C39 from '../assets/02_galeria/01_congreso_22/39.avif';
import C40 from '../assets/02_galeria/01_congreso_22/40.avif';
import C41 from '../assets/02_galeria/01_congreso_22/41.avif';
import C42 from '../assets/02_galeria/01_congreso_22/42.avif';
import C43 from '../assets/02_galeria/01_congreso_22/43.avif';
import C44 from '../assets/02_galeria/01_congreso_22/44.avif';
import C45 from '../assets/02_galeria/01_congreso_22/45.avif';
import C46 from '../assets/02_galeria/01_congreso_22/46.avif';
import C47 from '../assets/02_galeria/01_congreso_22/47.avif';
import C48 from '../assets/02_galeria/01_congreso_22/48.avif';
import C49 from '../assets/02_galeria/01_congreso_22/49.avif';
import C50 from '../assets/02_galeria/01_congreso_22/50.avif';
import C51 from '../assets/02_galeria/01_congreso_22/51.avif';
import C52 from '../assets/02_galeria/01_congreso_22/52.avif';
import C53 from '../assets/02_galeria/01_congreso_22/53.avif';
import C54 from '../assets/02_galeria/01_congreso_22/54.avif';
import C55 from '../assets/02_galeria/01_congreso_22/55.avif';
import C56 from '../assets/02_galeria/01_congreso_22/56.avif';
import C57 from '../assets/02_galeria/01_congreso_22/57.avif';
import C58 from '../assets/02_galeria/01_congreso_22/58.avif';
import C59 from '../assets/02_galeria/01_congreso_22/59.avif';
import C60 from '../assets/02_galeria/01_congreso_22/60.avif';
import C61 from '../assets/02_galeria/01_congreso_22/61.avif';
import C62 from '../assets/02_galeria/01_congreso_22/62.avif';
import C63 from '../assets/02_galeria/01_congreso_22/63.avif';
import C64 from '../assets/02_galeria/01_congreso_22/64.avif';
import C65 from '../assets/02_galeria/01_congreso_22/65.avif';
import C66 from '../assets/02_galeria/01_congreso_22/66.avif';
import C67 from '../assets/02_galeria/01_congreso_22/67.avif';
import C68 from '../assets/02_galeria/01_congreso_22/68.avif';
import C69 from '../assets/02_galeria/01_congreso_22/69.avif';
import C70 from '../assets/02_galeria/01_congreso_22/70.avif';
import C71 from '../assets/02_galeria/01_congreso_22/71.avif';
import C72 from '../assets/02_galeria/01_congreso_22/72.avif';
import C73 from '../assets/02_galeria/01_congreso_22/73.avif';

const Congreso22 = () => {
  const galleryImages = [

    { img: C01 }, { img: C02 }, { img: C03 }, { img: C04 }, { img: C05 }, { img: C06 }, { img: C07 }, { img: C08 }, { img: C09 }, { img: C10 }, { img: C11 }, { img: C12 }, { img: C13 }, { img: C14 }, { img: C15 }, { img: C16 }, { img: C17 }, { img: C18 }, { img: C19 }, { img: C20 }, { img: C21 }, { img: C22 }, { img: C23 }, { img: C24 }, { img: C25 }, { img: C26 }, { img: C27 }, { img: C28 }, { img: C29 }, { img: C30 }, { img: C31 }, { img: C32 }, { img: C33 }, { img: C34 }, { img: C35 }, { img: C36 }, { img: C37 }, { img: C38 }, { img: C39 }, { img: C40 }, { img: C41 }, { img: C42 }, { img: C43 }, { img: C44 }, { img: C45 }, { img: C46 }, { img: C47 }, { img: C48 }, { img: C49 }, { img: C50 }, { img: C51 }, { img: C52 }, { img: C53 }, { img: C54 }, { img: C55 }, { img: C56 }, { img: C57 }, { img: C58 }, { img: C59 }, { img: C60 }, { img: C61 }, { img: C62 }, { img: C63 }, { img: C64 }, { img: C65 }, { img: C66 }, { img: C67 }, { img: C68 }, { img: C69 }, { img: C70 }, { img: C71 }, { img: C72 }, { img: C73 },

  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">XXXII Consejo Nacional Ordinario y XVII Congreso Nacional Extraordinario <br />del 23 al 26 de agosto de 2022</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div>
  );
}

export default Congreso22