import PhotoGallery from "../components/PhotoGallery";
import comiteescalafon1 from '../assets/02_galeria/44_comite_escalafon/1.avif';
import comiteescalafon2 from '../assets/02_galeria/44_comite_escalafon/2.avif';
import comiteescalafon3 from '../assets/02_galeria/44_comite_escalafon/3.avif';
import comiteescalafon4 from '../assets/02_galeria/44_comite_escalafon/4.avif';
import comiteescalafon5 from '../assets/02_galeria/44_comite_escalafon/5.avif';
import comiteescalafon6 from '../assets/02_galeria/44_comite_escalafon/6.avif';
import comiteescalafon7 from '../assets/02_galeria/44_comite_escalafon/7.avif';

const ComitePlazasEscalafon = () => {
  const galleryImages = [
    { img: comiteescalafon1 }, { img: comiteescalafon2 }, { img: comiteescalafon3 }, { img: comiteescalafon4 }, { img: comiteescalafon5 }, { img: comiteescalafon6 }, { img: comiteescalafon7 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión con los integrantes del Comité para informar la logística de distribución de plazas<br /> (12 de febrero de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default ComitePlazasEscalafon