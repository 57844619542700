import PhotoGallery from "../components/PhotoGallery";
import reucon2501 from '../assets/02_galeria/13_reu_cond_25sep23/revcond01.avif';
import reucon2502 from '../assets/02_galeria/13_reu_cond_25sep23/revcond02.avif';
import reucon2503 from '../assets/02_galeria/13_reu_cond_25sep23/revcond03.avif';
import reucon2504 from '../assets/02_galeria/13_reu_cond_25sep23/revcond04.avif';


const ReuCond25sep23 = () => {
  const galleryImages = [
    { img: reucon2501 }, { img: reucon2502 }, { img: reucon2503 }, { img: reucon2504 }
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión con Delegados para hacer revisión a las Condiciones Generales de Trabajo <br /> (25 de septiembre de 2023)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default ReuCond25sep23