import PhotoGallery from "../components/PhotoGallery";
import exchigna01 from '../assets/02_galeria/20_chignahuapan/01.avif';
import exchigna02 from '../assets/02_galeria/20_chignahuapan/02.avif';
import exchigna03 from '../assets/02_galeria/20_chignahuapan/03.avif';
import exchigna04 from '../assets/02_galeria/20_chignahuapan/04.avif';
import exchigna05 from '../assets/02_galeria/20_chignahuapan/05.avif';
import exchigna06 from '../assets/02_galeria/20_chignahuapan/06.avif';
import exchigna07 from '../assets/02_galeria/20_chignahuapan/07.avif';
import exchigna08 from '../assets/02_galeria/20_chignahuapan/08.avif';
import exchigna09 from '../assets/02_galeria/20_chignahuapan/09.avif';
import exchigna10 from '../assets/02_galeria/20_chignahuapan/10.avif';
import exchigna11 from '../assets/02_galeria/20_chignahuapan/11.avif';
import exchigna12 from '../assets/02_galeria/20_chignahuapan/12.avif';
import exchigna13 from '../assets/02_galeria/20_chignahuapan/13.avif';
import exchigna14 from '../assets/02_galeria/20_chignahuapan/14.avif';
import exchigna15 from '../assets/02_galeria/20_chignahuapan/15.avif';
import exchigna16 from '../assets/02_galeria/20_chignahuapan/16.avif';
import exchigna17 from '../assets/02_galeria/20_chignahuapan/17.avif';
import exchigna18 from '../assets/02_galeria/20_chignahuapan/18.avif';
import exchigna19 from '../assets/02_galeria/20_chignahuapan/19.avif';

const ExcChignaNov23 = () => {
  const galleryImages = [
    { img: exchigna01 }, { img: exchigna02 }, { img: exchigna03 }, { img: exchigna04 }, { img: exchigna05 }, { img: exchigna06 }, { img: exchigna07 }, { img: exchigna08 }, { img: exchigna09 }, { img: exchigna10 }, { img: exchigna11 }, { img: exchigna12 }, { img: exchigna13 }, { img: exchigna14 }, { img: exchigna15 }, { img: exchigna16 }, { img: exchigna17 }, { img: exchigna18 }, { img: exchigna19 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Excursión a Chignahuapan (04/Nov/2023)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default ExcChignaNov23