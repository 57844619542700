import PhotoGallery from "../components/PhotoGallery";
import Logi01 from '../assets/02_galeria/06_log_cons23/1.avif';
import Logi02 from '../assets/02_galeria/06_log_cons23/2.avif';
import Logi03 from '../assets/02_galeria/06_log_cons23/3.avif';
import Logi04 from '../assets/02_galeria/06_log_cons23/4.avif';


const LogisConseCong23 = () => {
  const galleryImages = [

    { img: Logi01 }, { img: Logi02 }, { img: Logi03 }, { img: Logi04 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Planteando la logística para el Consejo y Congreso Nacional <br />Jueves 24 de Agosto 2023</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default LogisConseCong23