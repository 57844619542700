import PhotoGallery from "../components/PhotoGallery";
import infoissste1901 from '../assets/02_galeria/38_Info_issste_19/1.avif';
import infoissste1902 from '../assets/02_galeria/38_Info_issste_19/2.avif';
import infoissste1903 from '../assets/02_galeria/38_Info_issste_19/3.avif';
import infoissste1904 from '../assets/02_galeria/38_Info_issste_19/4.avif';
import infoissste1905 from '../assets/02_galeria/38_Info_issste_19/5.avif';
import infoissste1906 from '../assets/02_galeria/38_Info_issste_19/6.avif';
import infoissste1907 from '../assets/02_galeria/38_Info_issste_19/7.avif';


const InfoISSSTEdel19 = () => {
  const galleryImages = [
    { img: infoissste1901 }, { img: infoissste1902 }, { img: infoissste1903 }, { img: infoissste1904 }, { img: infoissste1905 }, { img: infoissste1906 }, { img: infoissste1907 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión de personal del ISSSTE con la Delegación 19 para dar información sobre Jubilación y Afore<br /> (24 de enero de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default InfoISSSTEdel19