import PhotoGallery from "../components/PhotoGallery";
import sorteocomescal1 from '../assets/02_galeria/45_sorteo_comite/1.avif';
import sorteocomescal2 from '../assets/02_galeria/45_sorteo_comite/2.avif';
import sorteocomescal3 from '../assets/02_galeria/45_sorteo_comite/3.avif';
import sorteocomescal4 from '../assets/02_galeria/45_sorteo_comite/4.avif';
import sorteocomescal5 from '../assets/02_galeria/45_sorteo_comite/5.avif';

const SorteoComitePlaza = () => {
  const galleryImages = [
    { img: sorteocomescal1 }, { img: sorteocomescal2 }, { img: sorteocomescal3 }, { img: sorteocomescal4 }, { img: sorteocomescal5 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Sorteo entre los integrantes del Comité (de 25 años de servicio en adelante)<br /> (13 de febrero de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default SorteoComitePlaza