import PhotoGallery from "../components/PhotoGallery";
import infoissste0601 from '../assets/02_galeria/40_Info_issste_06/1.avif';
import infoissste0602 from '../assets/02_galeria/40_Info_issste_06/2.avif';
import infoissste0603 from '../assets/02_galeria/40_Info_issste_06/3.avif';
import infoissste0604 from '../assets/02_galeria/40_Info_issste_06/4.avif';
import infoissste0605 from '../assets/02_galeria/40_Info_issste_06/5.avif';
import infoissste0606 from '../assets/02_galeria/40_Info_issste_06/6.avif';


const InfoISSSTEdel006 = () => {
  const galleryImages = [
    { img: infoissste0601 }, { img: infoissste0602 }, { img: infoissste0603 }, { img: infoissste0604 }, { img: infoissste0605 }, { img: infoissste0606 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión de personal del ISSSTE con la Delegación 06 para dar información sobre Jubilación y Afore<br /> (26 de enero de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default InfoISSSTEdel006