import PhotoGallery from "../components/PhotoGallery";
import reucomidecre01 from '../assets/02_galeria/51_reunion_comite_secretario/1.avif';
import reucomidecre02 from '../assets/02_galeria/51_reunion_comite_secretario/2.avif';
import reucomidecre03 from '../assets/02_galeria/51_reunion_comite_secretario/3.avif';
import reucomidecre04 from '../assets/02_galeria/51_reunion_comite_secretario/4.avif';
import reucomidecre05 from '../assets/02_galeria/51_reunion_comite_secretario/5.avif';
import reucomidecre06 from '../assets/02_galeria/51_reunion_comite_secretario/6.avif';
import reucomidecre07 from '../assets/02_galeria/51_reunion_comite_secretario/7.avif';
import reucomidecre08 from '../assets/02_galeria/51_reunion_comite_secretario/8.avif';

const ReunionComiteSecretario = () => {
  const galleryImages = [

    { img: reucomidecre01 }, { img: reucomidecre02 }, { img: reucomidecre03 }, { img: reucomidecre04 }, { img: reucomidecre05 }, { img: reucomidecre06 }, { img: reucomidecre07 }, { img: reucomidecre08 },

  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión con el Comité para lanzar Convocatoria a Secretario(a) General del SNTSEDATU <br />(26 de marzo de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default ReunionComiteSecretario