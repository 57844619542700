import PhotoGallery from "../components/PhotoGallery";
import exctlaco01 from '../assets/02_galeria/49_exc_tlaxco/1.avif';
import exctlaco02 from '../assets/02_galeria/49_exc_tlaxco/2.avif';
import exctlaco03 from '../assets/02_galeria/49_exc_tlaxco/3.avif';
import exctlaco04 from '../assets/02_galeria/49_exc_tlaxco/4.avif';
import exctlaco05 from '../assets/02_galeria/49_exc_tlaxco/5.avif';
import exctlaco06 from '../assets/02_galeria/49_exc_tlaxco/6.avif';
import exctlaco07 from '../assets/02_galeria/49_exc_tlaxco/7.avif';
import exctlaco08 from '../assets/02_galeria/49_exc_tlaxco/8.avif';
import exctlaco09 from '../assets/02_galeria/49_exc_tlaxco/9.avif';
import exctlaco10 from '../assets/02_galeria/49_exc_tlaxco/10.avif';

const ExcursionTlaco = () => {
  const galleryImages = [

    { img: exctlaco01 }, { img: exctlaco02 }, { img: exctlaco03 }, { img: exctlaco04 }, { img: exctlaco05 }, { img: exctlaco06 }, { img: exctlaco07 }, { img: exctlaco08 }, { img: exctlaco09 }, { img: exctlaco10 },

  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Excursión al parque acuático de Tlacotlapico en el Estado de Hidalgo <br /> (09 de marzo de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default ExcursionTlaco