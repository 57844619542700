import '../styles/Home.css';
import Tittle from '../components/Tittle';
import NavBarHome from '../components/NavBarHome';
import NavBarFooter from '../components/NavBarFooter';
import GoToTop from '../components/GoToTop';
import { SectionReleases, SectionMultimedia, SectionContact, SectionDocuments } from '../components/SectionHeadings';
import Novedades from '../components/Novedades';


const Home = () => {
  return (
    <>
      <header className='setHeading'>
        <Tittle />
        <NavBarHome />
      </header>
      <main className='setMain'>
        <Novedades />
        <SectionReleases />
        <SectionMultimedia />
        <SectionDocuments />
        <SectionContact />
      </main>
      <footer>
        <NavBarFooter />
      </footer>
      <GoToTop />
    </>
  )
}

export default Home;