import PhotoGallery from "../components/PhotoGallery";
import brazosCaidos01 from '../assets/02_galeria/17_brazos_caidos_ran/01.avif';
import brazosCaidos02 from '../assets/02_galeria/17_brazos_caidos_ran/02.avif';
import brazosCaidos03 from '../assets/02_galeria/17_brazos_caidos_ran/03.avif';
import brazosCaidos04 from '../assets/02_galeria/17_brazos_caidos_ran/04.avif';
import brazosCaidos05 from '../assets/02_galeria/17_brazos_caidos_ran/05.avif';
import brazosCaidos06 from '../assets/02_galeria/17_brazos_caidos_ran/06.avif';
import brazosCaidos07 from '../assets/02_galeria/17_brazos_caidos_ran/07.avif';
import brazosCaidos08 from '../assets/02_galeria/17_brazos_caidos_ran/08.avif';
import brazosCaidos09 from '../assets/02_galeria/17_brazos_caidos_ran/09.avif';
import brazosCaidos10 from '../assets/02_galeria/17_brazos_caidos_ran/10.avif';
import brazosCaidos11 from '../assets/02_galeria/17_brazos_caidos_ran/11.avif';
import brazosCaidos12 from '../assets/02_galeria/17_brazos_caidos_ran/12.avif';
import brazosCaidos13 from '../assets/02_galeria/17_brazos_caidos_ran/13.avif';
import brazosCaidos14 from '../assets/02_galeria/17_brazos_caidos_ran/14.avif';
import brazosCaidos15 from '../assets/02_galeria/17_brazos_caidos_ran/15.avif';
import brazosCaidos16 from '../assets/02_galeria/17_brazos_caidos_ran/16.avif';
import brazosCaidos17 from '../assets/02_galeria/17_brazos_caidos_ran/17.avif';
import brazosCaidos18 from '../assets/02_galeria/17_brazos_caidos_ran/18.avif';
import brazosCaidos19 from '../assets/02_galeria/17_brazos_caidos_ran/19.avif';
import brazosCaidos20 from '../assets/02_galeria/17_brazos_caidos_ran/20.avif';
import brazosCaidos21 from '../assets/02_galeria/17_brazos_caidos_ran/21.avif';
import brazosCaidos22 from '../assets/02_galeria/17_brazos_caidos_ran/22.avif';
import brazosCaidos23 from '../assets/02_galeria/17_brazos_caidos_ran/23.avif';
import brazosCaidos24 from '../assets/02_galeria/17_brazos_caidos_ran/24.avif';
import brazosCaidos25 from '../assets/02_galeria/17_brazos_caidos_ran/25.avif';
import brazosCaidos26 from '../assets/02_galeria/17_brazos_caidos_ran/26.avif';
import brazosCaidos27 from '../assets/02_galeria/17_brazos_caidos_ran/27.avif';
import brazosCaidos28 from '../assets/02_galeria/17_brazos_caidos_ran/28.avif';
import brazosCaidos29 from '../assets/02_galeria/17_brazos_caidos_ran/29.avif';
import brazosCaidos30 from '../assets/02_galeria/17_brazos_caidos_ran/30.avif';
import brazosCaidos31 from '../assets/02_galeria/17_brazos_caidos_ran/31.avif';
import brazosCaidos32 from '../assets/02_galeria/17_brazos_caidos_ran/32.avif';
import brazosCaidos33 from '../assets/02_galeria/17_brazos_caidos_ran/33.avif';
import brazosCaidos34 from '../assets/02_galeria/17_brazos_caidos_ran/34.avif';
import brazosCaidos35 from '../assets/02_galeria/17_brazos_caidos_ran/35.avif';
import brazosCaidos36 from '../assets/02_galeria/17_brazos_caidos_ran/36.avif';
import brazosCaidos37 from '../assets/02_galeria/17_brazos_caidos_ran/37.avif';
import brazosCaidos38 from '../assets/02_galeria/17_brazos_caidos_ran/38.avif';
import brazosCaidos39 from '../assets/02_galeria/17_brazos_caidos_ran/39.avif';
import brazosCaidos40 from '../assets/02_galeria/17_brazos_caidos_ran/40.avif';
import brazosCaidos41 from '../assets/02_galeria/17_brazos_caidos_ran/41.avif';
import brazosCaidos42 from '../assets/02_galeria/17_brazos_caidos_ran/42.avif';
import brazosCaidos43 from '../assets/02_galeria/17_brazos_caidos_ran/43.avif';
import brazosCaidos44 from '../assets/02_galeria/17_brazos_caidos_ran/44.avif';
import brazosCaidos45 from '../assets/02_galeria/17_brazos_caidos_ran/45.avif';
import brazosCaidos46 from '../assets/02_galeria/17_brazos_caidos_ran/46.avif';
import brazosCaidos47 from '../assets/02_galeria/17_brazos_caidos_ran/47.avif';
import brazosCaidos48 from '../assets/02_galeria/17_brazos_caidos_ran/48.avif';
import brazosCaidos49 from '../assets/02_galeria/17_brazos_caidos_ran/49.avif';
import brazosCaidos50 from '../assets/02_galeria/17_brazos_caidos_ran/50.avif';
import brazosCaidos51 from '../assets/02_galeria/17_brazos_caidos_ran/51.avif';
import brazosCaidos52 from '../assets/02_galeria/17_brazos_caidos_ran/52.avif';
import brazosCaidos53 from '../assets/02_galeria/17_brazos_caidos_ran/53.avif';
import brazosCaidos54 from '../assets/02_galeria/17_brazos_caidos_ran/54.avif';


const BazosCaidosRan = () => {
  const galleryImages = [
    { img: brazosCaidos01 }, { img: brazosCaidos02 }, { img: brazosCaidos03 }, { img: brazosCaidos04 }, { img: brazosCaidos05 }, { img: brazosCaidos06 }, { img: brazosCaidos07 }, { img: brazosCaidos08 }, { img: brazosCaidos09 }, { img: brazosCaidos10 }, { img: brazosCaidos11 }, { img: brazosCaidos12 }, { img: brazosCaidos13 }, { img: brazosCaidos14 }, { img: brazosCaidos15 }, { img: brazosCaidos16 }, { img: brazosCaidos17 }, { img: brazosCaidos18 }, { img: brazosCaidos19 }, { img: brazosCaidos20 }, { img: brazosCaidos21 }, { img: brazosCaidos22 }, { img: brazosCaidos23 }, { img: brazosCaidos24 }, { img: brazosCaidos25 }, { img: brazosCaidos26 }, { img: brazosCaidos27 }, { img: brazosCaidos28 }, { img: brazosCaidos29 }, { img: brazosCaidos30 }, { img: brazosCaidos31 }, { img: brazosCaidos32 }, { img: brazosCaidos33 }, { img: brazosCaidos34 }, { img: brazosCaidos35 }, { img: brazosCaidos36 }, { img: brazosCaidos37 }, { img: brazosCaidos38 }, { img: brazosCaidos39 }, { img: brazosCaidos40 }, { img: brazosCaidos41 }, { img: brazosCaidos42 }, { img: brazosCaidos43 }, { img: brazosCaidos44 }, { img: brazosCaidos45 }, { img: brazosCaidos46 }, { img: brazosCaidos47 }, { img: brazosCaidos48 }, { img: brazosCaidos49 }, { img: brazosCaidos50 }, { img: brazosCaidos51 }, { img: brazosCaidos52 }, { img: brazosCaidos53 }, { img: brazosCaidos54 }
  ]
  return (
    <div className="contGallery">
      <p className="titleGallery">Compañeras y Compañeros del Registro Agrario Nacional en el interior de la República en paro de labores por el maltrato de autoridades y la falta de respeto a nuestras prestaciones. Los usuarios se manifiestan también por la falta de atención</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default BazosCaidosRan