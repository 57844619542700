import PhotoGallery from "../components/PhotoGallery";
import infoissste022401 from '../assets/02_galeria/37_Info_issste_02_08/1.avif';
import infoissste022402 from '../assets/02_galeria/37_Info_issste_02_08/2.avif';
import infoissste022403 from '../assets/02_galeria/37_Info_issste_02_08/3.avif';
import infoissste022404 from '../assets/02_galeria/37_Info_issste_02_08/4.avif';
import infoissste022405 from '../assets/02_galeria/37_Info_issste_02_08/5.avif';
import infoissste022406 from '../assets/02_galeria/37_Info_issste_02_08/6.avif';

const InfoISSSTEdel0208 = () => {
  const galleryImages = [
    { img: infoissste022401 }, { img: infoissste022402 }, { img: infoissste022403 }, { img: infoissste022404 }, { img: infoissste022405 }, { img: infoissste022406 }
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión de personal del ISSSTE con las Delegaciones 02 y 08 para dar información sobre Jubilación y Afore<br /> (17 de enero de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default InfoISSSTEdel0208