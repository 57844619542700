import PhotoGallery from "../components/PhotoGallery";
import semsal02dia01 from '../assets/02_galeria/55_dia02/1.avif';
import semsal02dia02 from '../assets/02_galeria/55_dia02/2.avif';
import semsal02dia03 from '../assets/02_galeria/55_dia02/3.avif';

const Dia02SemSalud = () => {
  const galleryImages = [

    { img: semsal02dia01 }, { img: semsal02dia02 }, { img: semsal02dia03 },

  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Jornada de la Salud (Segundo Día) <br /> (14 de mayo de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default Dia02SemSalud