import PhotoGallery from "../components/PhotoGallery";
import Aca01 from '../assets/02_galeria/05_excur_aca_23/01.avif';
import Aca02 from '../assets/02_galeria/05_excur_aca_23/02.avif';
import Aca03 from '../assets/02_galeria/05_excur_aca_23/03.avif';
import Aca04 from '../assets/02_galeria/05_excur_aca_23/04.avif';
import Aca05 from '../assets/02_galeria/05_excur_aca_23/05.avif';
import Aca06 from '../assets/02_galeria/05_excur_aca_23/06.avif';

const ExcursAca23 = () => {
  const galleryImages = [

    { img: Aca01 }, { img: Aca02 }, { img: Aca03 }, { img: Aca04 }, { img: Aca05 }, { img: Aca06 }
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Excursión a la Casa de Acapulco <br />Agosto 2023</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default ExcursAca23