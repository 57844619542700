/* eslint-disable jsx-a11y/iframe-has-title */
import PhotoGallery from "../components/PhotoGallery";
import congr24ago2901 from '../assets/02_galeria/59_cong_24_ago29/1.avif';
import congr24ago2902 from '../assets/02_galeria/59_cong_24_ago29/2.avif';
import congr24ago2903 from '../assets/02_galeria/59_cong_24_ago29/3.avif';
import congr24ago2904 from '../assets/02_galeria/59_cong_24_ago29/4.avif';
import congr24ago2905 from '../assets/02_galeria/59_cong_24_ago29/5.avif';
import congr24ago2906 from '../assets/02_galeria/59_cong_24_ago29/6.avif';
import congr24ago2907 from '../assets/02_galeria/59_cong_24_ago29/7.avif';

const Congreso2024Agosto29 = () => {
  const galleryImages = [

    { img: congr24ago2901 }, { img: congr24ago2902 }, { img: congr24ago2903 }, { img: congr24ago2904 }, { img: congr24ago2905 }, { img: congr24ago2906 }, { img: congr24ago2907 },

  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">XV Congreso Nacional Ordinario <br />(29 de agosto de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
      <div className="videosCont">
        <iframe src="https://drive.google.com/file/d/1D9Lgz8lTD4mwRZ7RvnX7Pk6TYPm8PP5C/preview" className="singleVideo" />
        <iframe src="https://drive.google.com/file/d/1D6CYA8GN9bL0lkPfDYv_XZWWieGwtYSB/preview" className="singleVideo" />
      </div>
    </div >
  );
}

export default Congreso2024Agosto29