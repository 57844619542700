import PhotoGallery from "../components/PhotoGallery";
import reunionran01 from '../assets/02_galeria/50_reunion_ran/1.avif';
import reunionran02 from '../assets/02_galeria/50_reunion_ran/2.avif';
import reunionran03 from '../assets/02_galeria/50_reunion_ran/3.avif';
import reunionran04 from '../assets/02_galeria/50_reunion_ran/4.avif';
import reunionran05 from '../assets/02_galeria/50_reunion_ran/5.avif';
import reunionran06 from '../assets/02_galeria/50_reunion_ran/6.avif';
import reunionran07 from '../assets/02_galeria/50_reunion_ran/7.avif';
import reunionran08 from '../assets/02_galeria/50_reunion_ran/8.avif';

const ReunionRanMarzo = () => {
  const galleryImages = [

    { img: reunionran01 }, { img: reunionran02 }, { img: reunionran03 }, { img: reunionran04 }, { img: reunionran05 }, { img: reunionran06 }, { img: reunionran07 }, { img: reunionran08 },

  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión con el personal del RAN <br /> (14 de marzo de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default ReunionRanMarzo