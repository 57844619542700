import PhotoGallery from "../components/PhotoGallery";
import semsal03dia01 from '../assets/02_galeria/56_dia03/1.avif';
import semsal03dia02 from '../assets/02_galeria/56_dia03/2.avif';
import semsal03dia03 from '../assets/02_galeria/56_dia03/3.avif';
import semsal03dia04 from '../assets/02_galeria/56_dia03/4.avif';
import semsal03dia05 from '../assets/02_galeria/56_dia03/5.avif';
import semsal03dia06 from '../assets/02_galeria/56_dia03/6.avif';

const Dia03SemSalud = () => {
  const galleryImages = [

    { img: semsal03dia01 }, { img: semsal03dia02 }, { img: semsal03dia03 }, { img: semsal03dia04 }, { img: semsal03dia05 }, { img: semsal03dia06 },

  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Jornada de la Salud (Tercer Día) <br /> (15 de mayo de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default Dia03SemSalud