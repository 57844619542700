import PhotoGallery from "../components/PhotoGallery";
import faltas01 from '../assets/02_galeria/26_descuentos_por_faltas/01.avif';
import faltas02 from '../assets/02_galeria/26_descuentos_por_faltas/02.avif';
import faltas03 from '../assets/02_galeria/26_descuentos_por_faltas/03.avif';
import faltas04 from '../assets/02_galeria/26_descuentos_por_faltas/04.avif';
import faltas05 from '../assets/02_galeria/26_descuentos_por_faltas/05.avif';

const DescuentosPorFaltas = () => {
  const galleryImages = [
    { img: faltas01 }, { img: faltas02 }, { img: faltas03 }, { img: faltas04 }, { img: faltas05 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión con delegados para tratar los descuentos por "faltas injustificadas" a los compañeros sindicalizados <br /> (30 de noviembre de 2023)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default DescuentosPorFaltas