import PhotoGallery from "../components/PhotoGallery";
import ganaplaza01 from '../assets/02_galeria/47_ganadores_plazas/01.avif';
import ganaplaza02 from '../assets/02_galeria/47_ganadores_plazas/02.avif';
import ganaplaza03 from '../assets/02_galeria/47_ganadores_plazas/03.avif';
import ganaplaza04 from '../assets/02_galeria/47_ganadores_plazas/04.avif';
import ganaplaza05 from '../assets/02_galeria/47_ganadores_plazas/05.avif';
import ganaplaza06 from '../assets/02_galeria/47_ganadores_plazas/06.avif';
import ganaplaza07 from '../assets/02_galeria/47_ganadores_plazas/07.avif';

const GanadoresPlazas = () => {
  const galleryImages = [
    { img: ganaplaza01 }, { img: ganaplaza02 }, { img: ganaplaza03 }, { img: ganaplaza04 }, { img: ganaplaza05 }, { img: ganaplaza06 }, { img: ganaplaza07 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión con los beneficiarios del sorteo de plazas en SEDATU Ciudad de México <br /> (23 de febrero de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default GanadoresPlazas