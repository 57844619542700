/* eslint-disable jsx-a11y/iframe-has-title */
import PhotoGallery from "../components/PhotoGallery";
import congr24ago2801 from '../assets/02_galeria/58_cong_24_ago28/1.avif';
import congr24ago2802 from '../assets/02_galeria/58_cong_24_ago28/2.avif';
import congr24ago2803 from '../assets/02_galeria/58_cong_24_ago28/3.avif';
import congr24ago2804 from '../assets/02_galeria/58_cong_24_ago28/4.avif';
import congr24ago2805 from '../assets/02_galeria/58_cong_24_ago28/5.avif';
import congr24ago2806 from '../assets/02_galeria/58_cong_24_ago28/6.avif';
import congr24ago2807 from '../assets/02_galeria/58_cong_24_ago28/7.avif';
import congr24ago2808 from '../assets/02_galeria/58_cong_24_ago28/8.avif';
import congr24ago2809 from '../assets/02_galeria/58_cong_24_ago28/9.avif';
import congr24ago2810 from '../assets/02_galeria/58_cong_24_ago28/10.avif';
import congr24ago2811 from '../assets/02_galeria/58_cong_24_ago28/11.avif';
import congr24ago2812 from '../assets/02_galeria/58_cong_24_ago28/12.avif';
import congr24ago2813 from '../assets/02_galeria/58_cong_24_ago28/13.avif';
import congr24ago2814 from '../assets/02_galeria/58_cong_24_ago28/14.avif';
import congr24ago2815 from '../assets/02_galeria/58_cong_24_ago28/15.avif';
import congr24ago2816 from '../assets/02_galeria/58_cong_24_ago28/16.avif';
import congr24ago2817 from '../assets/02_galeria/58_cong_24_ago28/17.avif';
import congr24ago2818 from '../assets/02_galeria/58_cong_24_ago28/18.avif';
import congr24ago2819 from '../assets/02_galeria/58_cong_24_ago28/19.avif';
import congr24ago2820 from '../assets/02_galeria/58_cong_24_ago28/20.avif';
import congr24ago2821 from '../assets/02_galeria/58_cong_24_ago28/21.avif';
import congr24ago2822 from '../assets/02_galeria/58_cong_24_ago28/22.avif';
import congr24ago2823 from '../assets/02_galeria/58_cong_24_ago28/23.avif';
import congr24ago2824 from '../assets/02_galeria/58_cong_24_ago28/24.avif';
import congr24ago2825 from '../assets/02_galeria/58_cong_24_ago28/25.avif';
import congr24ago2826 from '../assets/02_galeria/58_cong_24_ago28/26.avif';
import congr24ago2827 from '../assets/02_galeria/58_cong_24_ago28/27.avif';
import congr24ago2828 from '../assets/02_galeria/58_cong_24_ago28/28.avif';
import congr24ago2829 from '../assets/02_galeria/58_cong_24_ago28/29.avif';
import congr24ago2830 from '../assets/02_galeria/58_cong_24_ago28/30.avif';
import congr24ago2831 from '../assets/02_galeria/58_cong_24_ago28/31.avif';
import congr24ago2832 from '../assets/02_galeria/58_cong_24_ago28/32.avif';
import congr24ago2833 from '../assets/02_galeria/58_cong_24_ago28/33.avif';
import congr24ago2834 from '../assets/02_galeria/58_cong_24_ago28/34.avif';
import congr24ago2835 from '../assets/02_galeria/58_cong_24_ago28/35.avif';
import congr24ago2836 from '../assets/02_galeria/58_cong_24_ago28/36.avif';
import congr24ago2837 from '../assets/02_galeria/58_cong_24_ago28/37.avif';
import congr24ago2838 from '../assets/02_galeria/58_cong_24_ago28/38.avif';
import congr24ago2839 from '../assets/02_galeria/58_cong_24_ago28/39.avif';
import congr24ago2840 from '../assets/02_galeria/58_cong_24_ago28/40.avif';
import congr24ago2841 from '../assets/02_galeria/58_cong_24_ago28/41.avif';
import congr24ago2842 from '../assets/02_galeria/58_cong_24_ago28/42.avif';
import congr24ago2843 from '../assets/02_galeria/58_cong_24_ago28/43.avif';
import congr24ago2844 from '../assets/02_galeria/58_cong_24_ago28/44.avif';

const Congreso2024Agosto28 = () => {
  const galleryImages = [

    { img: congr24ago2801 }, { img: congr24ago2802 }, { img: congr24ago2803 }, { img: congr24ago2804 }, { img: congr24ago2805 }, { img: congr24ago2806 }, { img: congr24ago2807 }, { img: congr24ago2808 }, { img: congr24ago2809 }, { img: congr24ago2810 }, { img: congr24ago2811 }, { img: congr24ago2812 }, { img: congr24ago2813 }, { img: congr24ago2814 }, { img: congr24ago2815 }, { img: congr24ago2816 }, { img: congr24ago2817 }, { img: congr24ago2818 }, { img: congr24ago2819 }, { img: congr24ago2820 }, { img: congr24ago2821 }, { img: congr24ago2822 }, { img: congr24ago2823 }, { img: congr24ago2824 }, { img: congr24ago2825 }, { img: congr24ago2826 }, { img: congr24ago2827 }, { img: congr24ago2828 }, { img: congr24ago2829 }, { img: congr24ago2830 }, { img: congr24ago2831 }, { img: congr24ago2832 }, { img: congr24ago2833 }, { img: congr24ago2834 }, { img: congr24ago2835 }, { img: congr24ago2836 }, { img: congr24ago2837 }, { img: congr24ago2838 }, { img: congr24ago2839 }, { img: congr24ago2840 }, { img: congr24ago2841 }, { img: congr24ago2842 }, { img: congr24ago2843 }, { img: congr24ago2844 },

  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">XV Congreso Nacional Ordinario <br />(28 de agosto de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
      <div className="videosCont">
        <iframe src="https://drive.google.com/file/d/17bZ3Kte4jlAaimLLSnHi1rDi8Bbnf8Ah/preview" className="singleVideo" />
        <iframe src="https://drive.google.com/file/d/1BJIVgyBkVnElrQMQ4Vs6axD7WjGMLqBY/preview" className="singleVideo" />
        <iframe src="https://drive.google.com/file/d/1BN9bmqsAcKjFtKNN8pLDQo2Y9DI-orrD/preview" className="singleVideo" />
        <iframe src="https://drive.google.com/file/d/1BLdK9T0AOyDR3crElSA2N__oKLOwJZFp/preview" className="singleVideo" />
        <iframe src="https://drive.google.com/file/d/1BTreO8Ooxm0gnt-dsrtNZPcR0xkq5E5B/preview" className="singleVideo" />
        <iframe src="https://drive.google.com/file/d/1BVtwRz-waqRJotI7lb9Ic5SpGeK_PXwX/preview" className="singleVideo" />
        <iframe src="https://drive.google.com/file/d/1BZ0Xr2iBkoPOV_bRNymO4hhFfiNb-aG_/preview" className="singleVideo" />
        <iframe src="https://drive.google.com/file/d/1BZ8TI9PHwulJjCGjlKu2UA23MqyMB5d_/preview" className="singleVideo" />
      </div>
    </div >
  );
}

export default Congreso2024Agosto28