import { Mailbox } from '../images/ImagesHome';
import '../styles/Novedades.css'

const Novedades = () => {
  return (
    <>
      <main className='newsContainer'>
        <p className='newsTitle'>Noticias</p> <hr className='lineNews' />
        <article className="container01">

          <section className='container02'>
            <div className='blc01'>
              <p className='pinNews'>
                News 01
              </p>
              <p className='sentenceNews'>
                Ponemos a su disposición los nombres y teléfonos del personal del ISSSTE que darán la asesoría necesaria para quien desee tramitar su jubilación y/o necesite información sobre su Afore Pensionissste (se encuentran ubicados en el edificio del ISSSTE en Buenavista) <br /> * Omar Martínez:<strong> 55 85 27 02 59</strong> <br /> * Luis Gutiérrez: <strong>55 73 40 44 37</strong>
              </p>
            </div>
            <div className='blc02'>
              <p className='pinNews'>
                News 02
              </p>
              <p className='sentenceNews'>
                Ponemos a su disposición reuniones por <strong>Google Meet</strong> para mantenerles informados. Solo se requiere que lo comenten con su Representante Sindical o con el Secretario de Organización del CEN Antonio Padilla Coronado, la reunión deberá programarse con varios días de anticipación y en el horario que ustedes necesiten. Esto permitirá una mejora en la comunicación.
              </p>
            </div>
          </section>
          <section className='container02'>
            <div className='blc03'>
              <p className='textMail'>Escribenos</p>
              <a href='mailto:escribenos@sntsedatu.org' > <Mailbox /> </a>
            </div>
          </section>



        </article>

      </main >
    </>

  );
}

export default Novedades;