import PhotoGallery from "../components/PhotoGallery";
import nuesindi01 from '../assets/02_galeria/21_nuevo_sindicato_y_amparo/01.avif';
import nuesindi02 from '../assets/02_galeria/21_nuevo_sindicato_y_amparo/02.avif';
import nuesindi03 from '../assets/02_galeria/21_nuevo_sindicato_y_amparo/03.avif';
import nuesindi04 from '../assets/02_galeria/21_nuevo_sindicato_y_amparo/04.avif';
import nuesindi05 from '../assets/02_galeria/21_nuevo_sindicato_y_amparo/05.avif';



const SeguimAmpYNueSindi = () => {
  const galleryImages = [
    { img: nuesindi01 }, { img: nuesindi02 }, { img: nuesindi03 }, { img: nuesindi04 }, { img: nuesindi05 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión con delegados para el seguimiento del Amparo a las Condiciones de Trabajo y comentar que el delegado Ignacio ya está haciendo trámites para la creación de su propio sindicato</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default SeguimAmpYNueSindi