import PhotoGallery from "../components/PhotoGallery";
import ampacondi1 from '../assets/02_galeria/18_amparo/amparo1.avif';
import ampacondi2 from '../assets/02_galeria/18_amparo/amparo2.avif';
import ampacondi3 from '../assets/02_galeria/18_amparo/amparo3.avif';
import ampacondi4 from '../assets/02_galeria/18_amparo/amparo4.avif';


const AmparoCondiciones2023 = () => {
  const galleryImages = [
    { img: ampacondi1 }, { img: ampacondi2 }, { img: ampacondi3 }, { img: ampacondi4 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión con delegados para informar sobre el Amparo a las Condiciones de Trabajo que será ingresado en el Tribunal de Conciliación y Arbitraje</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default AmparoCondiciones2023