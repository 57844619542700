import PhotoGallery from "../components/PhotoGallery";
import docPlazas01 from '../assets/02_galeria/46_reu_doc_plazas/1.avif';
import docPlazas02 from '../assets/02_galeria/46_reu_doc_plazas/2.avif';
import docPlazas03 from '../assets/02_galeria/46_reu_doc_plazas/3.avif';
import docPlazas04 from '../assets/02_galeria/46_reu_doc_plazas/4.avif';
import docPlazas05 from '../assets/02_galeria/46_reu_doc_plazas/5.avif';
import docPlazas06 from '../assets/02_galeria/46_reu_doc_plazas/6.avif';
import docPlazas07 from '../assets/02_galeria/46_reu_doc_plazas/7.avif';

const ReunionDocumentosPlazas = () => {
  const galleryImages = [
    { img: docPlazas01 }, { img: docPlazas02 }, { img: docPlazas03 }, { img: docPlazas04 }, { img: docPlazas05 }, { img: docPlazas06 }, { img: docPlazas07 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión con delegados para organizar la entrega de documentación de las plazas sorteadas, así como la entrega de un reconocimiento al personal del ISSSTE por su apoyo para informar a la base trabajadora sobre su retiro <br /> (20 de febrero de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default ReunionDocumentosPlazas