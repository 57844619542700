import '../styles/NavBarFooter.css';

const NavBarFooter = () => {
  return (
    <>
      <section className="footerContainer">
        <p className='footerTitle'>Enlaces Externos</p>
        <section className="containerLinks">
          <article className="containerBlock">
            <a className='footerLink' href="http://www.gob.mx/sedatu" target="blank"><p className='titleLink2'>SEDATU</p></a>
            <a className='footerLink' href="https://www.sat.gob.mx/home" target="blank"><p className='titleLink2'>SAT</p></a>
            <a className='footerLink' href="https://declaranet.gob.mx/" target="blank"><p className='titleLink2'>DeclaraNet</p></a>
          </article>
          <article className="containerBlock">
            <a className='footerLink' href="https://www.gob.mx/issste" target="blank"><p className='titleLink2'>ISSSTE</p></a>
            <a className='footerLink' href="https://www.gob.mx/fovissste" target="blank"><p className='titleLink2'>FOVISSSTE</p></a>
            <a className='footerLink' href="https://www.gob.mx/pensionissste"><p className='titleLink2'>PENSIONISSSTE</p></a>
          </article>
          <article className="containerBlock">
            <a className='footerLink' href="https://oficinavirtual.issste.gob.mx/" target="blank"><p className='titleLink2'>SINAVID</p></a>
            <a className='footerLink' href="https://sistemas.sedatu.gob.mx/e-recibos/#" target="blank"><p className='titleLink2'>E-RECIBOS</p></a>
            <a className='footerLink' href="https://mail.sedatu.gob.mx/" target="blank"><p className='titleLink2'>ZIMBRA</p></a>
          </article>
        </section>

      </section >
      <p className='registration'>©2023 by Sindicato Nacional de Trabajadores de la Secretaría de Desarrollo Agrario, Territorial y Urbano <br />
        Credits:Icons designed by <a href="https://www.flaticon.es/autores/riajulislam" title="riajulislam" target="blank">riajulislam</a> / <a href="https://www.flaticon.es/iconos-gratis/buzon" title="buzón iconos">Icon designed by Eucalyp - Flaticon</a> / <a href="https://www.freepik.es/vector-gratis/conjunto-nuevo-twitter-logotipo-iconos_57642971.htm?log-in=google">Imagen de starline</a> en Freepik </p>
    </>
  )
}

export default NavBarFooter;