import PhotoGallery from "../components/PhotoGallery";
import salud01 from '../assets/02_galeria/53_jornada_salud/1.avif';
import salud02 from '../assets/02_galeria/53_jornada_salud/2.avif';
import salud03 from '../assets/02_galeria/53_jornada_salud/3.avif';
import salud04 from '../assets/02_galeria/53_jornada_salud/4.avif';
import salud05 from '../assets/02_galeria/53_jornada_salud/5.avif';

const JornadaDeLaSalud = () => {
  const galleryImages = [

    { img: salud01 }, { img: salud02 }, { img: salud03 }, { img: salud04 }, { img: salud05 },

  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión con Delegados y Vocales para trabajar la logística de la Jornada de la Salud <br /> (18 de abril de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default JornadaDeLaSalud