import PhotoGallery from "../components/PhotoGallery";
import brindis01 from '../assets/02_galeria/33_brindis_comite/01.avif';
import brindis02 from '../assets/02_galeria/33_brindis_comite/02.avif';
import brindis03 from '../assets/02_galeria/33_brindis_comite/03.avif';
import brindis04 from '../assets/02_galeria/33_brindis_comite/04.avif';
import brindis05 from '../assets/02_galeria/33_brindis_comite/05.avif';
import brindis06 from '../assets/02_galeria/33_brindis_comite/06.avif';
import brindis07 from '../assets/02_galeria/33_brindis_comite/07.avif';
import brindis08 from '../assets/02_galeria/33_brindis_comite/08.avif';
import brindis09 from '../assets/02_galeria/33_brindis_comite/09.avif';
import brindis10 from '../assets/02_galeria/33_brindis_comite/10.avif';
import brindis11 from '../assets/02_galeria/33_brindis_comite/11.avif';
import brindis12 from '../assets/02_galeria/33_brindis_comite/12.avif';
import brindis13 from '../assets/02_galeria/33_brindis_comite/13.avif';
import brindis14 from '../assets/02_galeria/33_brindis_comite/14.avif';
import brindis15 from '../assets/02_galeria/33_brindis_comite/15.avif';
import brindis16 from '../assets/02_galeria/33_brindis_comite/16.avif';
import brindis17 from '../assets/02_galeria/33_brindis_comite/17.avif';
import brindis18 from '../assets/02_galeria/33_brindis_comite/18.avif';

const BrindisComite2023 = () => {
  const galleryImages = [
    { img: brindis01 }, { img: brindis02 }, { img: brindis03 }, { img: brindis04 }, { img: brindis05 }, { img: brindis06 }, { img: brindis07 }, { img: brindis08 }, { img: brindis09 }, { img: brindis10 }, { img: brindis11 }, { img: brindis12 }, { img: brindis13 }, { img: brindis14 }, { img: brindis15 }, { img: brindis16 }, { img: brindis17 }, { img: brindis18 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Brindis de Fin de año Comité <br /> (20 de diciembre de 2023)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default BrindisComite2023