import PhotoGallery from "../components/PhotoGallery";
import viVer01 from '../assets/02_galeria/14_vist_ver23/01.avif';
import viVer02 from '../assets/02_galeria/14_vist_ver23/02.avif';
import viVer03 from '../assets/02_galeria/14_vist_ver23/03.avif';
import viVer04 from '../assets/02_galeria/14_vist_ver23/04.avif';
import viVer05 from '../assets/02_galeria/14_vist_ver23/05.avif';
import viVer06 from '../assets/02_galeria/14_vist_ver23/06.avif';
import viVer07 from '../assets/02_galeria/14_vist_ver23/07.avif';
import viVer08 from '../assets/02_galeria/14_vist_ver23/08.avif';
import viVer09 from '../assets/02_galeria/14_vist_ver23/09.avif';
import viVer10 from '../assets/02_galeria/14_vist_ver23/10.avif';
import viVer11 from '../assets/02_galeria/14_vist_ver23/11.avif';
import viVer12 from '../assets/02_galeria/14_vist_ver23/12.avif';
import viVer13 from '../assets/02_galeria/14_vist_ver23/13.avif';
import viVer14 from '../assets/02_galeria/14_vist_ver23/14.avif';
import viVer15 from '../assets/02_galeria/14_vist_ver23/15.avif';


const VisitVeracruz23 = () => {
  const galleryImages = [
    { img: viVer01 }, { img: viVer02 }, { img: viVer03 }, { img: viVer04 }, { img: viVer05 }, { img: viVer06 }, { img: viVer07 }, { img: viVer08 }, { img: viVer09 }, { img: viVer10 }, { img: viVer11 }, { img: viVer12 }, { img: viVer13 }, { img: viVer14 }, { img: viVer15 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión de nuestro Secretario General en el estado de Veracruz</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default VisitVeracruz23