import PhotoGallery from "../components/PhotoGallery";
import simSep01 from '../assets/02_galeria/11_sim_sep23/azafran01.avif'
import simSep02 from '../assets/02_galeria/11_sim_sep23/azafran02.avif'
import simSep03 from '../assets/02_galeria/11_sim_sep23/azafran03.avif'
import simSep04 from '../assets/02_galeria/11_sim_sep23/azafran04.avif'
import simSep05 from '../assets/02_galeria/11_sim_sep23/azafran05.avif'
import simSep06 from '../assets/02_galeria/11_sim_sep23/azafran06.avif'
import simSep07 from '../assets/02_galeria/11_sim_sep23/azafran07.avif'
import simSep08 from '../assets/02_galeria/11_sim_sep23/azafran08.avif'
import simSep09 from '../assets/02_galeria/11_sim_sep23/azafran09.avif'
import simSep10 from '../assets/02_galeria/11_sim_sep23/azafran10.avif'
import simSep11 from '../assets/02_galeria/11_sim_sep23/azafran11.avif'
import simSep12 from '../assets/02_galeria/11_sim_sep23/azafran12.avif'
import simSep13 from '../assets/02_galeria/11_sim_sep23/azafran13.avif'
import simSep14 from '../assets/02_galeria/11_sim_sep23/azafran14.avif'
import simSep15 from '../assets/02_galeria/11_sim_sep23/modulos01.avif'
import simSep16 from '../assets/02_galeria/11_sim_sep23/modulos02.avif'
import simSep17 from '../assets/02_galeria/11_sim_sep23/modulos03.avif'
import simSep18 from '../assets/02_galeria/11_sim_sep23/modulos04.avif'
import simSep19 from '../assets/02_galeria/11_sim_sep23/modulos05.avif'
import simSep20 from '../assets/02_galeria/11_sim_sep23/modulos06.avif'
import simSep21 from '../assets/02_galeria/11_sim_sep23/modulos07.avif'
import simSep22 from '../assets/02_galeria/11_sim_sep23/modulos08.avif'
import simSep23 from '../assets/02_galeria/11_sim_sep23/modulos09.avif'
import simSep24 from '../assets/02_galeria/11_sim_sep23/modulos10.avif'
import simSep25 from '../assets/02_galeria/11_sim_sep23/modulos11.avif'
import simSep26 from '../assets/02_galeria/11_sim_sep23/modulos12.avif'
import simSep27 from '../assets/02_galeria/11_sim_sep23/modulos13.avif'
import simSep28 from '../assets/02_galeria/11_sim_sep23/modulos14.avif'
import simSep29 from '../assets/02_galeria/11_sim_sep23/modulos15.avif'
import simSep30 from '../assets/02_galeria/11_sim_sep23/modulos16.avif'
import simSep31 from '../assets/02_galeria/11_sim_sep23/modulos17.avif'
import simSep32 from '../assets/02_galeria/11_sim_sep23/modulos18.avif'
import simSep33 from '../assets/02_galeria/11_sim_sep23/modulos19.avif'
import simSep34 from '../assets/02_galeria/11_sim_sep23/modulos20.avif'
import simSep35 from '../assets/02_galeria/11_sim_sep23/modulos21.avif'
import simSep36 from '../assets/02_galeria/11_sim_sep23/modulos22.avif'
import simSep37 from '../assets/02_galeria/11_sim_sep23/modulos23.avif'
import simSep38 from '../assets/02_galeria/11_sim_sep23/modulos24.avif'
import simSep39 from '../assets/02_galeria/11_sim_sep23/modulos25.avif'
import simSep40 from '../assets/02_galeria/11_sim_sep23/modulos26.avif'
import simSep41 from '../assets/02_galeria/11_sim_sep23/modulos27.avif'
import simSep42 from '../assets/02_galeria/11_sim_sep23/modulos28.avif'
import simSep43 from '../assets/02_galeria/11_sim_sep23/modulos29.avif'
import simSep44 from '../assets/02_galeria/11_sim_sep23/modulos30.avif'
import simSep45 from '../assets/02_galeria/11_sim_sep23/modulos31.avif'
import simSep46 from '../assets/02_galeria/11_sim_sep23/modulos32.avif'
import simSep47 from '../assets/02_galeria/11_sim_sep23/nuevoLeon02.avif'
import simSep48 from '../assets/02_galeria/11_sim_sep23/nuevoLeon03.avif'
import simSep49 from '../assets/02_galeria/11_sim_sep23/nuevoLeon04.avif'
import simSep50 from '../assets/02_galeria/11_sim_sep23/nuevoLeon05.avif'
import simSep51 from '../assets/02_galeria/11_sim_sep23/comite01.avif'
import simSep52 from '../assets/02_galeria/11_sim_sep23/comite02.avif'
import simSep53 from '../assets/02_galeria/11_sim_sep23/comite03.avif'


const SimSep23 = () => {
  const galleryImages = [
    { img: simSep01 }, { img: simSep02 }, { img: simSep03 }, { img: simSep04 }, { img: simSep05 }, { img: simSep06 }, { img: simSep07 }, { img: simSep08 }, { img: simSep09 }, { img: simSep10 }, { img: simSep11 }, { img: simSep12 }, { img: simSep13 }, { img: simSep14 }, { img: simSep15 }, { img: simSep16 }, { img: simSep17 }, { img: simSep18 }, { img: simSep19 }, { img: simSep20 }, { img: simSep21 }, { img: simSep22 }, { img: simSep23 }, { img: simSep24 }, { img: simSep25 }, { img: simSep26 }, { img: simSep27 }, { img: simSep28 }, { img: simSep29 }, { img: simSep30 }, { img: simSep31 }, { img: simSep32 }, { img: simSep33 }, { img: simSep34 }, { img: simSep35 }, { img: simSep36 }, { img: simSep37 }, { img: simSep38 }, { img: simSep39 }, { img: simSep40 }, { img: simSep41 }, { img: simSep42 }, { img: simSep43 }, { img: simSep44 }, { img: simSep45 }, { img: simSep46 }, { img: simSep47 }, { img: simSep48 }, { img: simSep49 }, { img: simSep50 }, { img: simSep51 }, { img: simSep52 }, { img: simSep53 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Segundo Simulacro Nacional 2023 <br />Ejercicios efectuados en las sedes de la SEDATU y el edificio del Comité Nacional</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default SimSep23