import "../styles/NavBarHome.css";
import React from "react";
import { useNavigate } from 'react-router-dom';
import { TiThMenu } from "react-icons/ti";


const NavBarHome = () => {
  const navigate = useNavigate()

  const moveToReleases = () => {
    navigate("/comunicados")
  };

  const moveToMultimedia = () => {
    navigate("/multimedia")
  };

  /*const moveToDirectory = () => {
    navigate("/directorio")
  };*/

  const moveToDocuments = () => {
    navigate("/documentos")
  };

  const moveToAboutUs = () => {
    navigate("/nosotros")
  }

  const moveToContact = () => {
    navigate("/contacto")
  };

  return (
    <nav className="nav-menu">

      <input type="checkbox" name="" id="check" />
      <label for="check" class="checkbtn">
        <i class="menu-icon"><TiThMenu className="icon-hbg" /></i>
      </label>

      <ul>
        <li onClick={moveToReleases}>Comunicados</li>
        <li onClick={moveToMultimedia}>Galería </li>
        <li onClick={moveToDocuments}>PDF</li>
        <li onClick={moveToAboutUs}>Nosotros</li>
        <li onClick={moveToContact}>Contacto</li>
      </ul>
    </nav>
  )
}
export default NavBarHome;