/* eslint-disable jsx-a11y/iframe-has-title */
import PhotoGallery from "../components/PhotoGallery";
import congr24ago3001 from '../assets/02_galeria/60_cong_24_ago30/01.avif';
import congr24ago3002 from '../assets/02_galeria/60_cong_24_ago30/02.avif';
import congr24ago3003 from '../assets/02_galeria/60_cong_24_ago30/03.avif';


const Congreso2024Agosto30 = () => {
  const galleryImages = [

    { img: congr24ago3001 }, { img: congr24ago3002 }, { img: congr24ago3003 },

  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">XV Congreso Nacional Ordinario <br />(30 de agosto de 2024)</p>
      <PhotoGallery galleryImages={galleryImages} />
      <div className="videosCont">
        <iframe src="https://drive.google.com/file/d/1EmeFyU3OqhXiBgU1AiSm8bgbRRtcv9ax/preview" className="singleVideo" />
        <iframe src="https://drive.google.com/file/d/1EZD3rS1OGRJqVIF9qsKIwReCIWVNLLDZ/preview" className="singleVideo" />
        <iframe src="https://drive.google.com/file/d/1EIQzop1SxuGmEoj7yyQw-uhnXyXtFpkN/preview" className="singleVideo" />
        <iframe src="https://drive.google.com/file/d/1EMYetpMj5UwUKqi89iMcpLg9fa7xYdr5/preview" className="singleVideo" />
        <iframe src="https://drive.google.com/file/d/1EQ-HvRpx2W7azldg56APBnK_PxuIjdfN/preview" className="singleVideo" />
        <iframe src="https://drive.google.com/file/d/1E4G1rPudIDV5XG-gGCLHMk-3fH2FIADg/preview" className="singleVideo" />
      </div>
    </div >
  );
}

export default Congreso2024Agosto30