import PhotoGallery from "../components/PhotoGallery";
import vennoche01 from '../assets/02_galeria/29_venta_nochebuenas/01.avif';
import vennoche02 from '../assets/02_galeria/29_venta_nochebuenas/02.avif';
import vennoche03 from '../assets/02_galeria/29_venta_nochebuenas/03.avif';
import vennoche04 from '../assets/02_galeria/29_venta_nochebuenas/04.avif';
import vennoche05 from '../assets/02_galeria/29_venta_nochebuenas/05.avif';
import vennoche06 from '../assets/02_galeria/29_venta_nochebuenas/06.avif';
import vennoche07 from '../assets/02_galeria/29_venta_nochebuenas/07.avif';
import vennoche08 from '../assets/02_galeria/29_venta_nochebuenas/08.avif';
import vennoche09 from '../assets/02_galeria/29_venta_nochebuenas/09.avif';
import vennoche10 from '../assets/02_galeria/29_venta_nochebuenas/10.avif';
import vennoche11 from '../assets/02_galeria/29_venta_nochebuenas/11.avif';
import vennoche12 from '../assets/02_galeria/29_venta_nochebuenas/12.avif';
import vennoche13 from '../assets/02_galeria/29_venta_nochebuenas/13.avif';
import vennoche14 from '../assets/02_galeria/29_venta_nochebuenas/14.avif';
import vennoche15 from '../assets/02_galeria/29_venta_nochebuenas/15.avif';
import vennoche16 from '../assets/02_galeria/29_venta_nochebuenas/16.avif';


const VentaNochebuenas23 = () => {
  const galleryImages = [
    { img: vennoche01 }, { img: vennoche02 }, { img: vennoche03 }, { img: vennoche04 }, { img: vennoche05 }, { img: vennoche06 }, { img: vennoche07 }, { img: vennoche08 }, { img: vennoche09 }, { img: vennoche10 }, { img: vennoche11 }, { img: vennoche12 }, { img: vennoche13 }, { img: vennoche14 }, { img: vennoche15 }, { img: vennoche16 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Venta de Nochebuenas 2023 (Azafrán, Módulos, RAN y Comité)</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default VentaNochebuenas23