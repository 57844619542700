import PhotoGallery from "../components/PhotoGallery";
import reudelcon2301 from '../assets/02_galeria/15_reu_del_cond23/01.avif';
import reudelcon2302 from '../assets/02_galeria/15_reu_del_cond23/02.avif';
import reudelcon2303 from '../assets/02_galeria/15_reu_del_cond23/03.avif';
import reudelcon2304 from '../assets/02_galeria/15_reu_del_cond23/04.avif';
import reudelcon2305 from '../assets/02_galeria/15_reu_del_cond23/05.avif';
import reudelcon2306 from '../assets/02_galeria/15_reu_del_cond23/06.avif';
import reudelcon2307 from '../assets/02_galeria/15_reu_del_cond23/07.avif';
import reudelcon2308 from '../assets/02_galeria/15_reu_del_cond23/08.avif';
import reudelcon2309 from '../assets/02_galeria/15_reu_del_cond23/09.avif';
import reudelcon2310 from '../assets/02_galeria/15_reu_del_cond23/10.avif';
import reudelcon2311 from '../assets/02_galeria/15_reu_del_cond23/11.avif';
import reudelcon2312 from '../assets/02_galeria/15_reu_del_cond23/12.avif';
import reudelcon2313 from '../assets/02_galeria/15_reu_del_cond23/13.avif';
import reudelcon2314 from '../assets/02_galeria/15_reu_del_cond23/14.avif';
import reudelcon2315 from '../assets/02_galeria/15_reu_del_cond23/15.avif';
import reudelcon2316 from '../assets/02_galeria/15_reu_del_cond23/16.avif';
import reudelcon2317 from '../assets/02_galeria/15_reu_del_cond23/17.avif';
import reudelcon2318 from '../assets/02_galeria/15_reu_del_cond23/18.avif';
import reudelcon2319 from '../assets/02_galeria/15_reu_del_cond23/19.avif';
import reudelcon2320 from '../assets/02_galeria/15_reu_del_cond23/20.avif';
import reudelcon2321 from '../assets/02_galeria/15_reu_del_cond23/21.avif';
import reudelcon2322 from '../assets/02_galeria/15_reu_del_cond23/22.avif';
import reudelcon2323 from '../assets/02_galeria/15_reu_del_cond23/23.avif';
import reudelcon2324 from '../assets/02_galeria/15_reu_del_cond23/24.avif';


const ReunionDelCondiciones23 = () => {
  const galleryImages = [
    { img: reudelcon2301 }, { img: reudelcon2302 }, { img: reudelcon2303 }, { img: reudelcon2304 }, { img: reudelcon2305 }, { img: reudelcon2306 }, { img: reudelcon2307 }, { img: reudelcon2308 }, { img: reudelcon2309 }, { img: reudelcon2310 }, { img: reudelcon2311 }, { img: reudelcon2312 }, { img: reudelcon2313 }, { img: reudelcon2314 }, { img: reudelcon2315 }, { img: reudelcon2316 }, { img: reudelcon2317 }, { img: reudelcon2318 }, { img: reudelcon2319 }, { img: reudelcon2320 }, { img: reudelcon2321 }, { img: reudelcon2322 }, { img: reudelcon2323 }, { img: reudelcon2324 },
  ]

  return (
    <div className="contGallery">
      <p className="titleGallery">Reunión de la base trabajadora en sus Delegaciones Sindicales para la revisión de las Condiciones Generales de Trabajo</p>
      <PhotoGallery galleryImages={galleryImages} />
    </div >
  );
}

export default ReunionDelCondiciones23